import { useEffect, useState } from "react";

interface Props {
  onChange?: Function;
  disabled?: boolean;
  small?: boolean;
  className?: string;
  light?: boolean;
  id?: string;
  checked?: boolean;
  name?: string;
}

export default function Checkbox(props: Props) {
  const [_checked, _setChecked] = useState<boolean>(false);

  useEffect(() => {
    _setChecked(props.checked ?? false);
  }, [props]);

  return (
    <div className="custom-checkbox">
      <label className="switch">
        <input type="hidden" value="off" name={props.name} />
        <input
          disabled={props.disabled}
          onChange={(e) => {
            if (props.disabled) return;
            _setChecked(e.target.checked);
            props.onChange?.(e.target.checked);
          }}
          className={`${props.className ?? ""} ${props.small ? "small" : ""} ${
            props.light ? "light" : ""
          }`}
          id={props.id}
          defaultChecked={_checked}
          name={props.name}
          type="checkbox"
        />
        <div className="slider round"></div>
      </label>
    </div>
  );
}
