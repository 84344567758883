import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import Button from "../../../components/dom/Button";
import {
  COLLECTION_PERMISSIONS,
  patchCollection,
  refreshStoredCollection,
} from "../../../services/collection";
import { GROUP_PERMISSIONS } from "../../../services/groups";
import { selectCollection } from "../../../store/collectionSlice";
import { selectGroup } from "../../../store/groupSlice";
import { selectUser } from "../../../store/userSlice";
import { compressImage } from "../../../utils/generic";

export default function CollectionImages() {
  const collection = useSelector(selectCollection);
  const group = useSelector(selectGroup);
  const user = useSelector(selectUser);

  const { t } = useTranslation();

  const [images, setImages] = useState<Array<string>>([]);
  const [isSaveEnabled, setIsSaveEnabled] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (collection.images) setImages(collection.images);
  }, []);

  const canEdit = () => {
    if (user.isAdmin) return true;

    const myGroupPerms = group.members?.find(
      (e) => e.email === user.email
    )?.permissions;

    if (
      myGroupPerms?.includes(GROUP_PERMISSIONS.admin) ||
      myGroupPerms?.includes(GROUP_PERMISSIONS.collection)
    )
      return true;

    const myCollectionPerms = collection.members?.find(
      (e) => e.email === user.email
    )?.permissions;

    if (
      myCollectionPerms?.includes(COLLECTION_PERMISSIONS.admin) ||
      myCollectionPerms?.includes(COLLECTION_PERMISSIONS.census)
    )
      return true;

    return false;
  };

  const imagesUploadInput = (e: any) => {
    const images = Array.from(e.target.files);

    images.forEach(async (image: any) => {
      setIsLoading(true);
      const readAsync = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(image);

        reader.onloadend = async () => {
          if (reader.result) {
            const compressedImage = await compressImage(String(reader.result));
            resolve(compressedImage);
          }
        };
      });

      setImages((images) => {
        const imagesCopy = [...images];
        imagesCopy.push(readAsync as string);
        return [...imagesCopy];
      });
      setIsLoading(false);
      setIsSaveEnabled(true);
    });
  };

  const saveImages = async () => {
    setIsLoading(true);
    try {
      await patchCollection({ images: images, collectionId: collection._id });
      await refreshStoredCollection(collection._id);
      setIsSaveEnabled(false);
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };

  return (
    <section id="collection-images">
      <input
        onChange={imagesUploadInput}
        type="file"
        id="form-images"
        accept="image/png, image/jpeg"
        multiple
        hidden
      />
      {canEdit() ? (
        <>
          <p>{t("collection.upload_images_text")}</p>
          <Button
            className="mt-3"
            metadata={`${images.length}/10`}
            text={String(t("collection.upload_images"))}
            onClick={() => {
              document.getElementById("form-images")?.click();
            }}
          />
        </>
      ) : null}

      <div className="img-preview-container mt-2">
        {images.map((i, key) => {
          return (
            <div
              key={"img_" + Math.random().toString()}
              className="img-preview"
            >
              <input readOnly type="text" hidden value={i} name="images[]" />
              {canEdit() ? (
                <div
                  onClick={() => {
                    setIsSaveEnabled(true);
                    setImages((images) => {
                      const imagesCopy = [...images];
                      imagesCopy.splice(key, 1);
                      return [...imagesCopy];
                    });
                  }}
                  className="delete cursor-pointer"
                >
                  <span className="m-0">x</span>
                </div>
              ) : null}
              <div
                style={{
                  backgroundImage: `url(${i})`,
                  backgroundSize: "cover",
                  backgroundPosition: "center center",
                }}
                className="image"
              ></div>
            </div>
          );
        })}
      </div>
      {canEdit() ? (
        <Button
          loading={isLoading}
          className="mt-3"
          light={!isSaveEnabled}
          disabled={!isSaveEnabled}
          text={String(t("collection.save"))}
          onClick={() => saveImages()}
        />
      ) : null}
    </section>
  );
}
