import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { logout } from "../../services/auth";
import { getGroupsImIn, iGroup } from "../../services/groups";
import { selectUser } from "../../store/userSlice";
import Button from "../dom/Button";
import settingsSvg from "../../assets/icons/settings.svg";
import userSvg from "../../assets/icons/user.svg";
import { useNavigate } from "react-router-dom";
import Loader from "../dom/Loader";

export default function UserDropdown() {
  const user = useSelector(selectUser);
  const [groups, setGroups] = useState<Array<iGroup>>([]);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const isAdmin = () => {
    return user.isAdmin === true;
  };

  const loadGroups = async () => {
    setIsLoading(true);
    try {
      const data = await getGroupsImIn();
      setGroups(data);
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (!user._id) return;

    if (isAdmin()) loadGroups();
    // eslint-disable-next-line
  }, [user]);

  return (
    <div id="user-dropdown">
      <div className="block">
        <img className="avatar" src={userSvg} alt="user icon" />
        <p className="email">{user.email}</p>
        <Button
          className="mt-2"
          onClick={() => navigate("/account/profile")}
          text={String(t("settings.manage_account"))}
        />
      </div>
      {isAdmin() ? (
        <>
          <Button small text="Users" onClick={() => navigate("/users")} />
          <div className="hr"></div>
          <span className="group-title">{t("group.your_groups_title")}</span>
          {isLoading ? <Loader scale={0.4} /> : null}
          {groups.map((group, key) => {
            return (
              <div
                onClick={() => navigate("/group/" + group._id)}
                key={"group_" + key}
                className="group"
              >
                <span>{group.name}</span>
                {!group.verified ? (
                  <span className="not-verified">
                    {t("group.not_verified")}
                  </span>
                ) : null}
              </div>
            );
          })}
          <div
            onClick={() => navigate("/account/group")}
            className="create-group"
          >
            <img src={settingsSvg} alt="settings icon" />
            <span>{t("group.manage_group")}</span>
          </div>
        </>
      ) : null}
      <div className="hr"></div>
      <span onClick={() => navigate("/account/payment")} className="payment">
        {t("payment.payment_methods")}
      </span>
      <div className="hr"></div>
      <div className="block">
        <Button
          light
          onClick={() => logout()}
          text={String(t("auth.logout"))}
        />
      </div>
    </div>
  );
}
