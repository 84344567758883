import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import store from "../../services/redux";
import {
  selectIsCreateCollectionOpen,
  setIsCreateCollectionOpen,
} from "../../store/appSlice";
import closeSvg from "../../assets/icons/close.svg";
import { useTranslation } from "react-i18next";
import Button from "../dom/Button";
import Select from "../dom/Select";
import { Link } from "react-router-dom";
import { createCollection } from "../../services/collection";
import { selectGroup } from "../../store/groupSlice";
import { refreshStoredGroup } from "../../services/groups";
import { getAllPaymentMethods, iPaymentMethod } from "../../services/payment";
import { selectUser } from "../../store/userSlice";

export function openCreateCollection() {
  store.dispatch(setIsCreateCollectionOpen(true));
}
export function closeCreateCollection() {
  store.dispatch(setIsCreateCollectionOpen(false));
}

export default function CreateCollection() {
  const isOpen = useSelector(selectIsCreateCollectionOpen);
  const { t } = useTranslation();
  const [step, setStep] = useState(0);
  const [payload, setPayload] = useState<any>({});
  const [isLoading, setIsLoading] = useState(false);
  const totalSteps = 3;
  const group = useSelector(selectGroup);
  const [paymentmethods, setPaymentmethods] = useState<iPaymentMethod[]>([]);
  const user = useSelector(selectUser);

  const loadPaymentMethods = async () => {
    setIsLoading(true);
    try {
      console.log("load");
      const data = await getAllPaymentMethods();
      setPaymentmethods(data);
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (!user._id) return;

    setPayload({});
    loadPaymentMethods();
    setStep(0);
  }, [isOpen, user]);

  const createCollectionClick = async () => {
    setIsLoading(true);
    try {
      await createCollection({ ...payload, groupId: group._id });
      await refreshStoredGroup(group._id);
      closeCreateCollection();
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };

  const step0 = (
    <section>
      <span className="title">{t("collection.choose_type")}</span>
      <p>{t("collection.choose_type_text")}</p>
      <div className="collection-types">
        <div
          onClick={() => setPayload({ ...payload, gold: false })}
          className={`collection-type ${
            payload.gold === false ? "selected" : ""
          }`}
        >
          <p className="collection-type-title">
            {t("collection.type_classic")}
          </p>
          <p className="collection-type-text">
            {t("collection.type_classic_text")}
          </p>
        </div>
        <div
          onClick={() => setPayload({ ...payload, gold: true })}
          className={`collection-type gold ${
            payload.gold === true ? "selected" : ""
          }`}
        >
          <p className="collection-type-title">{t("collection.type_gold")}</p>
          <p className="collection-type-text">
            {t("collection.type_gold_text")}
          </p>
        </div>
      </div>
      <Button
        light={!(payload.gold === true || payload.gold === false)}
        disabled={!(payload.gold === true || payload.gold === false)}
        className="mt-5"
        text={String(t("collection.continue"))}
        onClick={() => setStep(step + 1)}
      />
    </section>
  );

  const step1 = (
    <section className="collection-info">
      <span className="title">{t("collection.choose_info")}</span>
      <p>{t("collection.choose_name_text")}</p>
      <input
        value={payload.name}
        onChange={(e) => setPayload({ ...payload, name: e.target.value })}
        className="collection-form-name"
        type="text"
        placeholder={String(t("collection.enter_name"))}
      />
      <div className="mt-5 buttons">
        <span className="previous" onClick={() => setStep(step - 1)}>
          {t("collection.previous")}
        </span>
        <Button
          light={!payload.name}
          disabled={!payload.name}
          text={String(t("collection.continue"))}
          onClick={() => setStep(step + 1)}
        />
      </div>
    </section>
  );

  const step2 = (
    <section className="payment-method">
      <span className="title">{t("collection.payment_method")}</span>
      <p>{t("collection.payment_method_text")}</p>
      <div className="payment-method-select-container">
        <label htmlFor="">{t("payment.optional")}</label>
        <Select
          onChange={(e: any) =>
            setPayload({ ...payload, paymentmethodId: e.target.value })
          }
          className="payment-method-select"
          defaultValue={"0"}
          options={[
            {
              value: "0",
              text: "Select payment method",
              disabled: true,
            },
            ...paymentmethods.map((p) => {
              return { text: p.name, value: p._id };
            }),
          ]}
        />
        <br />
        <Link onClick={() => closeCreateCollection()} to={"/account/payment"}>
          {t("payment.create_new")}
        </Link>
      </div>
      <div className="mt-5 buttons">
        <span className="previous" onClick={() => setStep(step - 1)}>
          {t("collection.previous")}
        </span>
        <Button
          loading={isLoading}
          text={String(t("collection.create"))}
          onClick={() => createCollectionClick()}
        />
      </div>
    </section>
  );

  return (
    <section
      className={`${isOpen ? "opened" : "closed"}`}
      id="create-collection"
    >
      <div className="header">
        <span className="close" onClick={() => closeCreateCollection()}>
          <img src={closeSvg} alt="close icon" />
        </span>
        <span className="title">
          {t("collection.create_title")} ({t("collection.create_step")}{" "}
          {step + 1} {t("collection.of")} {totalSteps})
        </span>
      </div>

      <div className="steps">
        {step === 0 ? step0 : null}
        {step === 1 ? step1 : null}
        {step === 2 ? step2 : null}
      </div>
    </section>
  );
}
