import { FormEvent, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import Button from "../../../components/dom/Button";
import {
  COLLECTION_PERMISSIONS,
  patchCollection,
  refreshStoredCollection,
} from "../../../services/collection";
import { GROUP_PERMISSIONS } from "../../../services/groups";
import { selectCollection } from "../../../store/collectionSlice";
import { selectGroup } from "../../../store/groupSlice";
import { selectUser } from "../../../store/userSlice";
import { formToObject } from "../../../utils/generic";

export default function CollectionInfo() {
  const { t } = useTranslation();
  const collection = useSelector(selectCollection);
  const user = useSelector(selectUser);
  const group = useSelector(selectGroup);

  const [isLoading, setIsLoading] = useState(false);
  const [isSaveBtnEnabled, setIsSaveBtnEnabled] = useState(false);

  const patchCollectionSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const data = await formToObject(e.target);
      await patchCollection(data);
      refreshStoredCollection(collection._id);
      setIsSaveBtnEnabled(false);
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };

  const canEdit = (): boolean => {
    if (user.isAdmin) return true;

    const myGroupPerms = group.members.find(
      (e) => e.email === user.email
    )?.permissions;

    if (
      myGroupPerms?.includes(GROUP_PERMISSIONS.admin) ||
      myGroupPerms?.includes(GROUP_PERMISSIONS.collection)
    )
      return true;

    const myCollPerms = collection.members.find(
      (e) => e.email === user.email
    )?.permissions;

    if (
      myCollPerms?.includes(COLLECTION_PERMISSIONS.admin) ||
      myCollPerms?.includes(COLLECTION_PERMISSIONS.update)
    )
      return true;

    return false;
  };

  return (
    <section className="collection-sub-page">
      <form onSubmit={patchCollectionSubmit}>
        <label htmlFor="collection-info-form-name">
          {t("collection.name")}
        </label>
        <input
          onInput={() => setIsSaveBtnEnabled(true)}
          type="text"
          name="name"
          required
          defaultValue={collection.name}
          disabled={!canEdit()}
        />
        <input
          name="collectionId"
          type="text"
          hidden
          defaultValue={collection._id}
          required
        />
        {canEdit() ? (
          <div>
            <Button
              type="submit"
              light={!isSaveBtnEnabled}
              disabled={!isSaveBtnEnabled}
              text={String(t("collection.save"))}
            />
          </div>
        ) : null}
      </form>
    </section>
  );
}
