import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  acceptInviteCollection,
  declineInviteCollection,
  iCollection,
} from "../../services/collection";
import { selectUser } from "../../store/userSlice";
import Button from "../dom/Button";

interface Props {
  collection: iCollection;
  onUpdate?: Function;
}

export default function CollectionCard({ collection, onUpdate }: Props) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const user = useSelector(selectUser);
  const [, setIsLoading] = useState(false);

  const isInvited = (email: string, collection: iCollection): boolean => {
    return collection.members.some(
      (e) => e.email === email && !e.isInviteAccepted
    );
  };

  const acceptInvite = async () => {
    setIsLoading(true);
    try {
      await acceptInviteCollection({ collectionId: collection._id });
      onUpdate?.();
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };

  const declineInvite = async () => {
    setIsLoading(true);
    try {
      await declineInviteCollection({ collectionId: collection._id });
      onUpdate?.();
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };

  return (
    <div
      onClick={() => navigate("/collection/" + collection._id)}
      className={`collection-card ${collection.gold ? "gold" : ""}`}
    >
      <span className="title">{collection.name}</span>
      <div className="nft">
        <span className="number">{collection.nftCount}</span>
        <span className="text">{t("collection.nfts")}</span>
      </div>
      {isInvited(user.email, collection) ? (
        <div className="buttons">
          <Button
            onClick={(e: any) => {
              e.stopPropagation();
              acceptInvite();
            }}
            small
            text={String(t("collection.accept_invite"))}
          />
          <Button
            onClick={(e: any) => {
              e.stopPropagation();
              declineInvite();
            }}
            small
            error
            text={String(t("collection.decline_invite"))}
          />
        </div>
      ) : null}
    </div>
  );
}
