import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { openCreateCollection } from "../../../components/createcollection/CreateCollection";
import Button from "../../../components/dom/Button";
import { getCollections, iCollection } from "../../../services/collection";
import { GROUP_PERMISSIONS, iGroup } from "../../../services/groups";
import { selectGroup } from "../../../store/groupSlice";
import { selectUser } from "../../../store/userSlice";
import cleftSvg from "../../../assets/icons/cleft.svg";
import crightSvg from "../../../assets/icons/cright.svg";
import CollectionCard from "../../../components/collectioncard/CollectionCard";
import Loader from "../../../components/dom/Loader";

const PAGE_DIM = 25;

export default function GroupCollections() {
  const { t } = useTranslation();
  const user = useSelector(selectUser);
  const group = useSelector(selectGroup);

  const [collections, setCollections] = useState<Array<iCollection>>([]);
  const [page, setPage] = useState(1);

  const [isGold, setIsGold] = useState<boolean | undefined>(undefined);

  const [isLoading, setIsLoading] = useState(false);

  const loadCollections = async (
    groupId: string,
    page: number,
    gold?: boolean
  ) => {
    setIsLoading(true);
    try {
      const data = await getCollections(page, groupId, gold);
      setCollections(data);
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    loadCollections(group._id, page, isGold);
    // eslint-disable-next-line
  }, [page, group, isGold]);

  const canCreate = (group: iGroup): boolean => {
    if (user.isAdmin) return true;

    const myPerms = group.members.find(
      (e) => e.email === user.email
    )?.permissions;

    if (!myPerms) return false;

    if (
      myPerms.includes(GROUP_PERMISSIONS.admin) ||
      myPerms.includes(GROUP_PERMISSIONS.collection)
    )
      return true;

    return false;
  };

  return (
    <section className="group-sub-page">
      <div className="group-collection-header">
        {/* <span className="title">{t("group.collection")}</span> */}
        <span></span>
        {canCreate(group) ? (
          <Button
            onClick={() => openCreateCollection()}
            text={String(t("group.create_collection"))}
          />
        ) : null}
      </div>
      <div className="buttons d-flex gap-2 mt-2">
        <Button
          small
          text={String(t("group.collection_all"))}
          onClick={() => setIsGold(undefined)}
          disabled={typeof isGold === "undefined"}
          light={typeof isGold === "undefined"}
        />
        <Button
          small
          text={String(t("group.collection_classic"))}
          onClick={() => setIsGold(false)}
          disabled={typeof isGold === "boolean" && !isGold}
          light={typeof isGold === "boolean" && !isGold}
        />
        <Button
          small
          text={String(t("group.collection_gold"))}
          onClick={() => setIsGold(true)}
          disabled={typeof isGold === "boolean" && isGold}
          light={typeof isGold === "boolean" && isGold}
        />
      </div>
      {isLoading ? (
        <div className="mt-5">
          <Loader />
        </div>
      ) : (
        <>
          {collections.length === 0 ? (
            <span className="mt-5">{t("group.no_collections")}</span>
          ) : (
            <div className="collection-list">
              {collections.map((collection, key) => {
                return <CollectionCard key={key} collection={collection} />;
              })}
            </div>
          )}
        </>
      )}

      {collections.length >= PAGE_DIM || page > 1 ? (
        <div className="page">
          <img
            onClick={() => {
              if (page <= 1) return;
              setPage(page - 1);
              setCollections([]);
            }}
            src={cleftSvg}
            alt="chevron icon"
            className={`chevron ${page > 1 ? "" : "disabled"}`}
          />
          <span>
            {t("collection.page")} {page}
          </span>
          <img
            onClick={() => {
              if (collections.length < PAGE_DIM) return;
              setPage(page + 1);
              setCollections([]);
            }}
            src={crightSvg}
            alt="chevron icon"
            className={`chevron ${
              collections.length >= PAGE_DIM ? "" : "disabled"
            }`}
          />
        </div>
      ) : null}
    </section>
  );
}
