import { FormEvent, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Button from "../../../components/dom/Button";
import { closeModal, openModal } from "../../../components/modal/Modal";
import {
  deleteGroup,
  GROUP_PERMISSIONS,
  iGroup,
  leaveGroup,
  refreshStoredGroup,
  transferGroup,
  verifyGroup,
} from "../../../services/groups";
import { selectGroup } from "../../../store/groupSlice";
import { selectUser } from "../../../store/userSlice";
import { formToObject } from "../../../utils/generic";

export default function GroupAccess() {
  const group = useSelector(selectGroup);
  const user = useSelector(selectUser);
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const isOwner = (group: iGroup) => {
    const myPerms = group.members.find(
      (e) => e.email === user.email
    )?.permissions;

    if (!myPerms) return false;

    if (myPerms.includes(GROUP_PERMISSIONS.admin)) return true;

    return false;
  };

  const leaveGroupSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    setIsLoading(true);
    try {
      const data = formToObject(e.target);
      await leaveGroup(data);
      navigate("/");
      closeModal();
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };

  const leaveGroupConfirmModal = (
    <section id="remove-user-group-modal">
      <span className="title">
        {t("group.leave_group_confirm")} {group.name}
      </span>
      <form onSubmit={leaveGroupSubmit}>
        <input
          type="text"
          required
          hidden
          name="groupId"
          defaultValue={group._id}
        />
        <div className="buttons">
          <Button
            onClick={() => closeModal()}
            className="cancel"
            light
            text={String(t("group.cancel"))}
          />
          <Button
            type="submit"
            className="confirm"
            error
            text={String(t("group.confirm_leave_button"))}
          />
        </div>
      </form>
    </section>
  );

  const canDelete = (group: iGroup) => {
    if (user.isAdmin) return true;

    const myPerms = group.members.find(
      (e) => e.email === user.email
    )?.permissions;

    if (!myPerms) return false;

    if (
      myPerms.includes(GROUP_PERMISSIONS.admin) ||
      myPerms.includes(GROUP_PERMISSIONS.delete)
    )
      return true;

    return false;
  };

  const canVerify = (group: iGroup) => {
    if (user.isAdmin) return true;
  };

  const deleteGroupSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const data = formToObject(e.target);
      await deleteGroup(data);
      navigate("/account/group");
      closeModal();
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };

  const deleteGroupConfirmModal = (
    <section id="remove-user-group-modal">
      <span className="title">
        {t("group.delete_confirm")} {group.name}
      </span>
      <form onSubmit={deleteGroupSubmit}>
        <input
          type="text"
          required
          hidden
          name="groupId"
          defaultValue={group._id}
        />
        <div className="buttons">
          <Button
            onClick={() => closeModal()}
            className="cancel"
            light
            text={String(t("group.cancel"))}
          />
          <Button
            type="submit"
            className="confirm"
            error
            text={String(t("group.confirm_delete_button"))}
          />
        </div>
      </form>
    </section>
  );

  const transferGroupSubmit = async (e: FormEvent<HTMLElement>) => {
    e.preventDefault();

    setIsLoading(true);

    try {
      const data = formToObject(e.target);
      await transferGroup(data);
      await refreshStoredGroup(group._id);
      closeModal();
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };

  const groupTransferModal = (group: iGroup) => (
    <section id="invite-user-group-modal">
      <div className="head">
        <div className="title">
          {t("group.transfer_text")} {group.name}
        </div>
      </div>
      <div className="body">
        <form onSubmit={transferGroupSubmit}>
          <label htmlFor="invite-user-group-form">
            {t("group.transfer_user")}
          </label>
          <div className="line">
            <input
              type="email"
              required
              id="transfer-group-form"
              name="email"
              placeholder="lorem.ipsum@email.com"
            />
            <Button
              loading={isLoading}
              type="submit"
              text={String(t("group.transfer"))}
            />
          </div>
          <input
            type="text"
            hidden
            required
            name="groupId"
            defaultValue={group._id}
          />
        </form>
      </div>
    </section>
  );

  return (
    <section className="group-sub-page">
      <span className="title">{t("group.access")}</span>
      {isOwner(group) ? (
        <p className="m-0">{t("group.cant_leave_if_owner")}</p>
      ) : null}
      <div>
        <Button
          className="my-1"
          disabled={isOwner(group)}
          light
          text={String(t("group.leave"))}
          type="submit"
          onClick={() => openModal(leaveGroupConfirmModal)}
        />
      </div>
      <div>
        <Button
          className={`my-1 ${!isOwner(group) ? "d-none" : ""}`}
          light
          text={String(t("group.transfer"))}
          onClick={() => {
            openModal(groupTransferModal(group));
          }}
        />
      </div>
      {canDelete(group) ? (
        <>
          <div className="hr"></div>
          <div>
            <Button
              onClick={() => {
                if (group.verified)
                  return alert("group.delete_disabled_if_verified");
                openModal(deleteGroupConfirmModal);
              }}
              error
              text={String(t("group.delete"))}
            />
          </div>
        </>
      ) : null}
      {canVerify(group) && !group.verified ? (
        <>
          <div className="hr"></div>
          <div>
            <Button
              loading={isLoading}
              onClick={async () => {
                setIsLoading(true);
                try {
                  await verifyGroup({ groupId: group._id });
                  await refreshStoredGroup(group._id);
                } catch (error) {
                  console.log(error);
                }
                setIsLoading(false);
              }}
              text={String(t("group.verify"))}
            />
          </div>
        </>
      ) : null}
    </section>
  );
}
