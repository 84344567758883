import axios from "axios";
import { LANG } from "./i18next";

export enum NFT_BLOCKCHAIN_STATUS {
  TO_MINT = "TO_MINT",
  MINTING = "MINTING",
  MINTED = "MINTED",

  TO_BURN = "TO_BURN",
  BURNING = "BURNING",
  BURNED = "BURNED",

  TO_LOCK = "TO_LOCK",
  LOCKING = "LOCKING",
  LOCKED = "LOCKED",

  TO_UNLOCK = "TO_UNLOCK",
  UNLOCKING = "UNLOCKING",
  UNLOCKED = "MINTED", // use MINTED
}

export enum NFT_SELL_STATUS {
  NOT_FOR_SALE = "NOT_FOR_SALE",
  FOR_SALE = "FOR_SALE",
  WAITING_PAYMENT = "WAITING_PAYMENT",
}

export enum NFT_OWNER_TYPE {
  ORIGINAL = "ORIGINAL",
  RESOLD = "RESOLD",
}

type Guest = {
  firstName?: string;
  secondName?: string;
  email?: string;
};

type BlockchainTransaction = {
  type: "mint" | "lock" | "burn" | "unlock";
  id: string;
  date: Date;
};

export type payloadHotelType = {
  checkin?: string;
  checkout?: string;
  reference?: string;
  extra?: string;

  rooms?: Array<{
    name?: string;
    guestsAdults?: number;
    guestsKids?: number;
    amenities?: string;
  }>;

  note?: string;
  hotel?: string;
  stars?: string;
  website?: string;
  email?: string;

  location?: { label: string; value: object; address_components: object };
};

export interface iNft {
  _id?: string;

  payload?: payloadHotelType;
  owner?: string; // email of owner

  lang?: LANG | string;
  collectionId?: string;

  sellStatus?: NFT_SELL_STATUS;
  ownerType?: NFT_OWNER_TYPE;

  lockDate?: string;

  images?: Array<string>;

  originalPrice?: number;
  currentPrice?: number;
  marketPrice?: number;

  guests?: Array<Guest>;

  gold?: boolean;

  // web3
  blockchainStatus?: NFT_BLOCKCHAIN_STATUS;
  contractAddress?: string;
  solidityId?: number;
  blockchainTransactions?: Array<BlockchainTransaction>;
  errors?: Array<any>;

  _createdAt?: any;
}

export async function createNft(payload: any): Promise<iNft> {
  const response = await axios.post("/nft", payload);
  return response.data;
}

export async function patchNft(payload: any): Promise<iNft> {
  const response = await axios.patch("/nft", payload);
  return response.data;
}

export async function getNftsOfCollectionPaginated(
  collectionId: string,
  page = 1,
  query?: object
): Promise<Array<iNft>> {
  let url = `/nft/paginate?page=${page}&collectionId=${collectionId}&cache=false`;
  if (query) url += `&query=${JSON.stringify(query)}`;

  const response = await axios.get(url);
  return response.data;
}

export async function getNft(id: string) {
  const response = await axios.get("/nft/" + id);
  return response.data;
}

export async function deleteNft(payload: any) {
  const response = await axios.delete("/nft", { data: payload });
  return response.data;
}
