import axios from "axios";
import { t } from "i18next";

export function formToObject(form: any, includeVoid = true) {
  const myFormData = new FormData(form);

  const formDataObj: any = {};
  myFormData.forEach((value, key) => {
    if (!includeVoid && value === "") return;

    if (key.includes("[]")) {
      key = String(key).replace("[]", "");
      if (!formDataObj[key]) formDataObj[key] = [];
      formDataObj[key].push(value);
      return;
    }

    if (!key.includes("[]") && key.includes("[") && key.includes("]")) {
      const data = key.replace("]", "").split("[");
      if (!formDataObj[data[0]]) formDataObj[data[0]] = {};
      formDataObj[data[0]][data[1]] = value;
      return;
    }

    return (formDataObj[key] = value);
  });

  return formDataObj;
}

export const autoGrowTextArea = (e: any) => {
  e.target.style.height = "5px";
  e.target.style.height = e.target.scrollHeight + "px";
};

export const datetimeToString = (isodate: any, short = false, time = false) => {
  if (!isodate) return undefined;

  const months = [
    "january",
    "february",
    "march",
    "april",
    "may",
    "june",
    "july",
    "august",
    "september",
    "october",
    "november",
    "december",
  ].map((e) => t("date." + e));

  const date = new Date(isodate);
  let result = `${date.getDate()} ${
    months[date.getMonth()]
  } ${date.getFullYear()}`;

  if (time) {
    result += ` ${date.getHours()}:${date.getMinutes()} GMT${
      date.getTimezoneOffset() / 60
    }`;
  }

  if (short) {
    result = `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
    if (time) {
      result += ` ${date.getHours()}:${date.getMinutes()}`;
    }
  }

  return result;
};

export function compressImage(dataUrl: string, newWidth: number = 1024) {
  return new Promise((resolve) => {
    let image: any, oldWidth, oldHeight, newHeight, canvas;

    // Create a temporary image so that we can compute the height of the downscaled image.
    image = new Image();
    image.onload = function () {
      oldWidth = image.width;
      oldHeight = image.height;
      newHeight = Math.floor((oldHeight / oldWidth) * newWidth);

      // Create a temporary canvas to draw the downscaled image on.
      canvas = document.createElement("canvas");
      canvas.width = newWidth;
      canvas.height = newHeight;

      // Draw the downscaled image on the canvas and resolve the promise with the new data URL.
      canvas.getContext("2d")!.drawImage(image, 0, 0, newWidth, newHeight);
      resolve(canvas.toDataURL());
    };
    image.src = dataUrl;
  });
}

export const isEmail = (email: string) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

export function toggleScroll(bool: boolean) {
  console.log("TODO scroll lock");
}

export async function getAddressComponents(placeId: string) {
  const response = await axios.get("/google/places/" + placeId);
  return response.data;
}
