import { FormEvent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import {
  isLoggedIn,
  login,
  logout,
  register,
  sendRecoveryPasswordEmail,
  sendVerificaitonEmail,
  resetPassword,
} from "../../services/auth";
import { selectUser } from "../../store/userSlice";
import { formToObject } from "../../utils/generic";
import Button from "../dom/Button";
import Checkbox from "../dom/Checkbox";
import GoogleAuth from "./GoogleAuth";

const EMAIL_DELAY = 60;

export default function Auth() {
  const { pathname } = useLocation();
  const user = useSelector(selectUser);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);

  const [emailVerificationTimer, setEmailVerificationTimer] = useState(0);
  const [resetPasswordTimer, setResetPasswordTimer] = useState(0);
  const [resetPasswordToken, setResetPasswordToken] = useState("");

  const [element, setElement] = useState<
    | "login"
    | "register"
    | "forgot_password"
    | "reset_password"
    | "verify_email"
    | "reset_password_success"
  >("login");

  const [error, setError] = useState("");

  useEffect(() => {
    setError("");
  }, [element]);

  useEffect(() => {
    if (isLoggedIn() && user.emailVerified) navigate("/");

    if (isLoggedIn() && !user.emailVerified) setElement("verify_email");
    // eslint-disable-next-line
  }, [user]);

  useEffect(() => {
    if (pathname.includes("reset-password")) {
      const params = new URL(window.location as any).searchParams;
      const token = params.get("token");

      if (token) setResetPasswordToken(token);
      setElement("reset_password");
    }
  }, [pathname]);

  const loginFormSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    setIsLoading(true);
    try {
      const data = formToObject(e.target);
      await login(data);
    } catch (error: any) {
      const errorMessage = String(error.response.data.message).toLowerCase();

      if (errorMessage.includes("not found"))
        setError(String(t("auth.user_not_found")));
    }
    setIsLoading(false);
  };

  const loginForm = (
    <form onSubmit={(e) => loginFormSubmit(e)}>
      <label htmlFor="login-form-email">{t("auth.email")}</label>
      <input required id="login-form-email" type="email" name="email" />
      <label htmlFor="login-form-password">{t("auth.password")}</label>
      <input
        required
        id="login-form-password"
        type="password"
        name="password"
      />

      <span
        onClick={() => setElement("forgot_password")}
        className="forgot-password"
      >
        {t("auth.forgot_password")}
      </span>

      <Button
        loading={isLoading}
        type="submit"
        text={String(t("auth.login"))}
      />
    </form>
  );

  const registerFormSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    setIsLoading(true);
    try {
      const data = formToObject(e.target);
      await register(data);
      await login(data);
      setElement("verify_email");
    } catch (error: any) {
      const errorMessage = String(error.response.data.message).toLowerCase();

      setError(errorMessage);
    }
    setIsLoading(false);
  };

  const registerForm = (
    <form onSubmit={(e) => registerFormSubmit(e)}>
      <label htmlFor="register-form-email">{t("auth.email")}</label>
      <input required id="register-form-email" type="email" name="email" />
      <label htmlFor="register-form-password">{t("auth.password")}</label>
      <input
        required
        id="register-form-password"
        type="password"
        name="password"
        minLength={6}
      />

      <div className="marketing-toggle">
        <label htmlFor="register-form-marketingEmails">
          {t("auth.marketing_emails")}
        </label>
        <Checkbox
          id="register-form-marketingEmails"
          name="marketingEmails"
          checked
        />
      </div>

      <input
        required
        hidden
        name="redirect"
        type="text"
        defaultValue={process.env.REACT_APP_DASHBOARD_URI}
      />

      <Button
        loading={isLoading}
        type="submit"
        text={String(t("auth.register"))}
      />
    </form>
  );

  const authButtons = (
    <div className="auth-buttons">
      <div
        onClick={() => setElement("login")}
        className={`${element === "login" ? "active" : ""}`}
      >
        <span>{t("auth.login")}</span>
      </div>
      <div
        onClick={() => setElement("register")}
        className={`${element === "register" ? "active" : ""}`}
      >
        <span>{t("auth.register")}</span>
      </div>
    </div>
  );

  const forgotPasswordFormSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    setIsLoading(true);
    try {
      const data = formToObject(e.target);
      await sendRecoveryPasswordEmail(data);

      setResetPasswordTimer(EMAIL_DELAY);
      const intervalId = setInterval(() => {
        setResetPasswordTimer((n) => {
          if (n === 0) {
            clearInterval(intervalId);
            return 0;
          }
          return n - 1;
        });
      }, 1000);
    } catch (error: any) {
      console.log(error);
      const errorMessage = String(error.response.data.message).toLowerCase();

      setError(errorMessage);
    }
    setIsLoading(false);
  };

  const forgotPassword = (
    <div className="password-section">
      <span className="back-btn" onClick={() => setElement("login")}>
        {t("auth.back")}
      </span>
      <form onSubmit={forgotPasswordFormSubmit}>
        <label htmlFor="forgot-password-form-email">{t("auth.email")}</label>
        <input
          required
          id="forgot-password-form-email"
          type="email"
          name="email"
        />

        <input
          required
          hidden
          name="redirect"
          type="text"
          defaultValue={
            process.env.REACT_APP_FRONTEND_URI +
            "/auth/reset-password?token=__TOKEN__"
          }
        />

        <Button
          metadata={resetPasswordTimer}
          loading={isLoading}
          disabled={resetPasswordTimer > 0}
          type="submit"
          text={String(t("auth.send"))}
        />
      </form>
    </div>
  );

  const resetPasswordSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    setIsLoading(true);
    try {
      const data = formToObject(e.target);
      await resetPassword(data);
      navigate("/auth");
      setElement("reset_password_success");
    } catch (error: any) {
      const errorMessage = String(error.response.data.message).toLowerCase();

      console.log(error);

      setError(errorMessage);
    }
    setIsLoading(false);
  };

  const resetPasswordForm = (
    <div className="password-section">
      <span className="back-btn" onClick={() => setElement("login")}>
        {t("auth.back")}
      </span>
      <form onSubmit={resetPasswordSubmit}>
        <label htmlFor="reset-password-form-password">
          {t("auth.password")}
        </label>
        <input
          required
          id="reset-password-form-password"
          type="password"
          name="password"
        />
        <input
          required
          id="reset-password-form-token"
          type="text"
          name="token"
          defaultValue={resetPasswordToken}
          hidden
        />
        <input
          required
          hidden
          name="redirect"
          type="text"
          defaultValue={process.env.REACT_APP_FRONTEND_URI}
        />

        <Button
          loading={isLoading}
          type="submit"
          text={String(t("auth.reset"))}
        />
      </form>
    </div>
  );

  const verifyEmailFormSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    setIsLoading(true);
    try {
      const data = formToObject(e.target);
      await sendVerificaitonEmail(data);

      setEmailVerificationTimer(EMAIL_DELAY);
      const intervalId = setInterval(() => {
        setEmailVerificationTimer((n) => {
          if (n === 0) {
            clearInterval(intervalId);
            return 0;
          }
          return n - 1;
        });
      }, 1000);
    } catch (error: any) {
      console.log(error);
      const errorMessage = String(error.response.data.message).toLowerCase();

      setError(errorMessage);
    }
    setIsLoading(false);
  };

  const verifyEmail = (
    <div className="password-section">
      <span
        className="back-btn"
        onClick={async () => {
          setElement("login");
          await logout();
        }}
      >
        {t("auth.back")}
      </span>
      <form onSubmit={verifyEmailFormSubmit}>
        <p>{t("auth.verify_email")}</p>
        <input
          required
          hidden
          name="email"
          type="email"
          defaultValue={user.email}
        />
        <input
          required
          hidden
          name="redirect"
          type="text"
          defaultValue={process.env.REACT_APP_DASHBOARD_URI}
        />
        <Button
          metadata={emailVerificationTimer}
          disabled={emailVerificationTimer > 0}
          loading={isLoading}
          type="submit"
          text={String(t("auth.send"))}
        />
      </form>
    </div>
  );

  const resetPasswordSuccess = (
    <div className="reset_password_success">
      {t("auth.reset_password_success")}
      <Button
        onClick={() => setElement("login")}
        text={String(t("auth.login"))}
      />
    </div>
  );

  return (
    <section id="auth">
      <div className="box">
        {element === "login" || element === "register" ? authButtons : null}
        {element === "login" ? loginForm : null}
        {element === "register" ? registerForm : null}
        {element === "forgot_password" ? forgotPassword : null}
        {element === "reset_password" ? resetPasswordForm : null}
        {element === "verify_email" ? verifyEmail : null}
        {element === "reset_password_success" ? resetPasswordSuccess : null}
        <div className="error">
          <span>{error}</span>
        </div>
        <div className="hr"></div>
        <div className="my-2">
          {element === "login" || element === "register" ? (
            <GoogleAuth />
          ) : null}
        </div>
      </div>
    </section>
  );
}
