import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import Auth from "./components/auth/Auth";
import { isLoggedIn } from "./services/auth";
import { useSelector } from "react-redux";
import { selectUser } from "./store/userSlice";
import AccountSettings from "./pages/AccountSettings/AccountSettings";
import Group from "./pages/Group/Group";
import HomeCollections from "./pages/HomeCollections/HomeCollections";
import Collection from "./pages/Collection/Collection";
import Users from "./pages/Users/Users";

export default function Router() {
  return (
    <Routes>
      <Route path="" element={<HomeCollections />} />
      <Route path="auth" element={<Auth />} />
      <Route path="auth/reset-password" element={<Auth />} />
      <Route path="account/*" element={<AccountSettings />} />
      <Route path="group/:id/*" element={<Group />} />
      <Route path="collection/:id/*" element={<Collection />} />
      <Route path="users" element={<Users />} />
    </Routes>
  );
}

export function ScrollToTop() {
  const { pathname } = useLocation();
  const user = useSelector(selectUser);
  const navigate = useNavigate();

  useEffect(() => {
    if (!pathname.includes("reset-password")) {
      if (!isLoggedIn()) navigate("/auth");
      if (isLoggedIn() && user._id && !user.emailVerified) navigate("/auth");
    }

    window.scrollTo({ top: 0, behavior: "auto" });
    // eslint-disable-next-line
  }, [pathname, user]);
  return <></>;
}
