import Loader from "./Loader";

interface Props {
  text?: string;
  onClick?: Function;
  disabled?: boolean;
  loading?: boolean;
  small?: boolean;
  metadata?: string | number;
  className?: string;
  light?: boolean;
  error?: boolean;
  type?: "button" | "submit" | "reset" | undefined;
  id?: string;
  secondary?: boolean;
}

export default function Button(props: Props) {
  return (
    <button
      id={props.id}
      type={props.type ?? "button"}
      onClick={(e) => props.onClick?.(e)}
      disabled={props.disabled || props.loading}
      className={`custom-button ${props.small ? "small" : ""} 
      ${props.light ? "light" : ""} 
      ${props.error ? "error" : ""} 
      ${props.secondary ? "secondary" : ""} ${props.className}`}
    >
      {props.text}
      <div className="metadata">
        {props.loading ? (
          <Loader light={!props.light} scale={props.small ? 0.2 : 0.3} />
        ) : (
          ""
        )}
        {props.metadata && !props.loading ? props.metadata : ""}
      </div>
    </button>
  );
}
