import { FormEvent, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Button from "../../../components/dom/Button";
import { closeModal, openModal } from "../../../components/modal/Modal";
import {
  COLLECTION_PERMISSIONS,
  deleteCollection,
  iCollection,
  leaveCollection,
  refreshStoredCollection,
  transferCollection,
} from "../../../services/collection";
import { GROUP_PERMISSIONS } from "../../../services/groups";
import { selectCollection } from "../../../store/collectionSlice";
import { selectGroup } from "../../../store/groupSlice";
import { selectUser } from "../../../store/userSlice";
import { formToObject } from "../../../utils/generic";

export default function CollectionAccess() {
  const group = useSelector(selectGroup);
  const collection = useSelector(selectCollection);
  const user = useSelector(selectUser);
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const isGroupAdmin = () => {
    const myPerms = group.members?.find(
      (e) => e.email === user.email
    )?.permissions;

    if (
      myPerms?.includes(GROUP_PERMISSIONS.admin) ||
      myPerms?.includes(GROUP_PERMISSIONS.collection)
    )
      return true;

    return false;
  };

  const isOwner = () => {
    const myPerms = collection.members?.find(
      (e) => e.email === user.email
    )?.permissions;

    if (!myPerms) return false;

    if (myPerms.includes(COLLECTION_PERMISSIONS.admin)) return true;

    return false;
  };

  const leaveCollectionSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    setIsLoading(true);
    try {
      const data = formToObject(e.target);
      await leaveCollection(data);
      navigate("/");
      closeModal();
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };

  const leaveCollectionConfirmModal = (
    <section id="remove-user-group-modal">
      <span className="title">
        {t("collection.leave_group_confirm")} {collection.name}
      </span>
      <form onSubmit={leaveCollectionSubmit}>
        <input
          type="text"
          required
          hidden
          name="collectionId"
          defaultValue={collection._id}
        />
        <div className="buttons">
          <Button
            onClick={() => closeModal()}
            className="cancel"
            light
            text={String(t("collection.cancel"))}
          />
          <Button
            type="submit"
            className="confirm"
            error
            text={String(t("collection.confirm_leave_button"))}
          />
        </div>
      </form>
    </section>
  );

  const canDelete = () => {
    if (user.isAdmin) return true;

    const myGrpupPerms = group.members?.find(
      (e) => e.email === user.email
    )?.permissions;

    if (
      myGrpupPerms?.includes(GROUP_PERMISSIONS.admin) ||
      myGrpupPerms?.includes(GROUP_PERMISSIONS.collection)
    )
      return true;

    const myCollectionPerms = group.members?.find(
      (e) => e.email === user.email
    )?.permissions;

    if (
      myCollectionPerms?.includes(GROUP_PERMISSIONS.admin) ||
      myCollectionPerms?.includes(GROUP_PERMISSIONS.delete)
    )
      return true;

    return false;
  };

  const deleteCollectionSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const data = formToObject(e.target);
      await deleteCollection(data);
      navigate("/");
      closeModal();
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };

  const deleteCollectionConfirmModal = (
    <section id="remove-user-group-modal">
      <span className="title">
        {t("collection.delete_confirm")} {collection.name}
      </span>
      <form onSubmit={deleteCollectionSubmit}>
        <input
          type="text"
          required
          hidden
          name="collectionId"
          defaultValue={collection._id}
        />
        <div className="buttons">
          <Button
            onClick={() => closeModal()}
            className="cancel"
            light
            text={String(t("collection.cancel"))}
          />
          <Button
            type="submit"
            className="confirm"
            error
            text={String(t("collection.confirm_delete_button"))}
          />
        </div>
      </form>
    </section>
  );

  const transferCollectionSubmit = async (e: FormEvent<HTMLElement>) => {
    e.preventDefault();

    setIsLoading(true);

    try {
      const data = formToObject(e.target);
      await transferCollection(data);
      await refreshStoredCollection(collection._id);
      closeModal();
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };

  const collectionTransferModal = (
    <section id="invite-user-group-modal">
      <div className="head">
        <div className="title">
          {t("collection.transfer_text")} {collection.name}
        </div>
      </div>
      <div className="body">
        <form onSubmit={transferCollectionSubmit}>
          <label htmlFor="invite-user-group-form">
            {t("collection.transfer_user")}
          </label>
          <div className="line">
            <input
              type="email"
              required
              id="transfer-group-form"
              name="email"
              placeholder="lorem.ipsum@email.com"
            />
            <Button
              loading={isLoading}
              type="submit"
              text={String(t("collection.transfer"))}
            />
          </div>
          <input
            type="text"
            hidden
            required
            name="collectionId"
            defaultValue={collection._id}
          />
        </form>
      </div>
    </section>
  );

  return (
    <section className="collection-sub-page">
      <span className="title">{t("collection.access")}</span>
      {isOwner() || isGroupAdmin() ? (
        <p className="m-0">{t("collection.cant_leave_if_owner")}</p>
      ) : null}
      <div>
        <Button
          className="my-1"
          disabled={isOwner() || isGroupAdmin()}
          light
          text={String(t("collection.leave"))}
          type="submit"
          onClick={() => openModal(leaveCollectionConfirmModal)}
        />
      </div>
      <div>
        <Button
          className={`my-1 ${!isOwner() ? "d-none" : ""}`}
          light
          text={String(t("collection.transfer"))}
          onClick={() => openModal(collectionTransferModal)}
        />
      </div>
      {canDelete() ? (
        <>
          <div className="hr"></div>
          <div>
            <Button
              onClick={() => openModal(deleteCollectionConfirmModal)}
              error
              text={String("collection.delete")}
            />
          </div>
        </>
      ) : null}
    </section>
  );
}
