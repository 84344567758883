import axios from "axios";
import store from "./redux";

export interface iPendingPayment {
  _id: string;

  // peding payments x user
  userId: string;
  reverseTransferId: string;

  // pending payments x group (payment method)
  paymentmethodId: string;
  groupId: string;
  collectionId: string;

  // both
  chargeId: string;
  amount: number;
  nftId: string;

  fulfilled: boolean;
}

export enum PAYMENT_METHODS_PERMISSIONS {
  admin = "admin",
  view_data = "view_data",
  delete = "delete",
  update = "update",
  methods = "methods",
  give_permission = "give_permission",
}

export const paymentMethodsRoles: Array<{
  name: string;
  permissions: Array<PAYMENT_METHODS_PERMISSIONS>;
}> = [
  {
    name: "administrator",
    permissions: [
      PAYMENT_METHODS_PERMISSIONS.update,
      PAYMENT_METHODS_PERMISSIONS.give_permission,
      PAYMENT_METHODS_PERMISSIONS.view_data,
      PAYMENT_METHODS_PERMISSIONS.delete,
      PAYMENT_METHODS_PERMISSIONS.methods,
    ],
  },
  // {
  //   name: "member",
  //   permissions: [
  //     PAYMENT_METHODS_PERMISSIONS.view_data,
  //     PAYMENT_METHODS_PERMISSIONS.use,
  //   ],
  // },
];

interface member {
  email: string;
  permissions: Array<PAYMENT_METHODS_PERMISSIONS>;
  isInviteAccepted: boolean;
}

export type BillingDetails = {
  vat?: string;
  fiscalCode?: string;
  name?: string;
  zip?: string;
  city?: string;
  address?: string;
  contactName?: string;
  contactPhone?: string;
  recipientCode?: string;
  pec?: string;
};

export interface iPaymentMethod {
  _id: string;

  name: string;

  // payout
  stripeId: string;
  stripeAccountDetailSubmitted: boolean;

  // subscription
  customerId: string;
  subscriptionId: string;
  subscriptionStatus: string;

  subscriptionCurrentStartDate: Date;
  subscriptionCurrentEndDate: Date;
  subscriptionLastUpdate: Date;

  // billing details
  billingDetails: BillingDetails;

  members: Array<member>;
}

export async function getPaymentMethod(id: string): Promise<iPaymentMethod> {
  const response = await axios.get("/paymentmethod/" + id);
  return response.data;
}

export async function createPaymentMethod(
  payload: any
): Promise<iPaymentMethod> {
  const response = await axios.post("/paymentmethod", payload);
  return response.data;
}

export async function updatePaymentMethod(
  payload: any
): Promise<iPaymentMethod> {
  const response = await axios.patch("/paymentmethod", payload);
  return response.data;
}

export async function deletePaymentMethod(payload: any): Promise<any> {
  const response = await axios.delete("/paymentmethod", { data: payload });
  return response.data;
}

export async function getAllPaymentMethods(): Promise<Array<iPaymentMethod>> {
  const response = await axios.get("/paymentmethod");
  return response.data;
}

export async function getPendingPaymentStats(
  id: string,
  collectionId?: string
): Promise<{
  totalAmount: number;
  amountToFulfill: number;
  subscriptionAmount: {
    subscriptionStripeAmount: number;
    subscriptionTakyonAmount: number;
  };
}> {
  let q = `/paymentmethod/${id}/stats`;
  if (collectionId) q += `?collectionId=${collectionId}`;
  const response = await axios.get(q);
  return response.data;
}

export async function getPendingPayments(
  id: string,
  page = 1,
  collectionId?: string
): Promise<iPendingPayment[]> {
  let q = `/paymentmethod/${id}/pending?page=${page}`;
  if (collectionId) q += `&collectionId=${collectionId}`;
  const response = await axios.get(q);
  return response.data;
}

export async function fulfillPayments(payload: any) {
  const response = await axios.post("/paymentmethod/fulfill-payments", payload);
  return response.data;
}

export async function getSubscriptionLink(paymentmethodId: string) {
  const response = await axios.get(`/subscription/${paymentmethodId}/checkout`);
  return response.data;
}

export async function getSubscriptionPortal(paymentmethodId: string) {
  const response = await axios.get(`/subscription/${paymentmethodId}/portal`);
  return response.data;
}
