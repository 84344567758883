import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Route,
  Routes,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import nftSvg from "../../assets/icons/nft.svg";
import censusSvg from "../../assets/icons/census.svg";
import imagesSvg from "../../assets/icons/images.svg";
import usersSvg from "../../assets/icons/users.svg";
import presetSvg from "../../assets/icons/preset.svg";
import paymentSvg from "../../assets/icons/payment.svg";
import statisticsSvg from "../../assets/icons/statistics.svg";
import infoSvg from "../../assets/icons/info.svg";
import settingsSvg from "../../assets/icons/settings.svg";
import crightSvg from "../../assets/icons/cright.svg";
import CollectionNfts from "./sub/CollectionNfts";
import { selectCollection } from "../../store/collectionSlice";
import { refreshStoredCollection } from "../../services/collection";
import { useSelector } from "react-redux";
import CollectionMembers from "./sub/CollectionMembers";
import { refreshStoredGroup } from "../../services/groups";
import CollectionCensus from "./sub/CollectionCensus";
import CollectionInfo from "./sub/CollectionInfo";
import CollectionAccess from "./sub/CollectionAccess";
import CollectionImages from "./sub/CollectionImages";
import CreateNft from "../../components/createnft/CreateNft";
import Loader from "../../components/dom/Loader";
import CollectionPayment from "./sub/CollectionPayment";

function Redirect() {
  const navigate = useNavigate();
  navigate("nfts");
  return <></>;
}

function CollectionRouter() {
  return (
    <Routes>
      <Route path="/nfts" element={<CollectionNfts />} />
      <Route path="/nfts/edit/" element={<CreateNft />} />
      <Route path="/nfts/edit/:nftId" element={<CreateNft />} />
      <Route path="/members" element={<CollectionMembers />} />
      <Route path="/census" element={<CollectionCensus />} />
      <Route path="/images" element={<CollectionImages />} />
      <Route path="/payment" element={<CollectionPayment />} />
      <Route path="/info" element={<CollectionInfo />} />
      <Route path="/access" element={<CollectionAccess />} />
      <Route path="/" element={<Redirect />} />
    </Routes>
  );
}

interface iMenuItem {
  name: string;
  icon?: any;
  separator?: boolean;
  uri?: string;
  selected?: boolean;
  primary?: boolean;
  disabled?: boolean;
}

export default function Collection() {
  const { t } = useTranslation();

  const menu: Array<iMenuItem> = [
    {
      name: t("collection.nfts"),
      uri: "nfts",
      icon: nftSvg,
      separator: true,
    },
    {
      name: t("collection.census"),
      uri: "census",
      icon: censusSvg,
    },
    {
      name: t("collection.images"),
      uri: "images",
      icon: imagesSvg,
    },
    {
      name: t("collection.members"),
      uri: "members",
      icon: usersSvg,
      separator: true,
    },
    {
      name: t("collection.preset"),
      uri: "preset",
      icon: presetSvg,
      disabled: true,
    },
    {
      name: t("collection.payments"),
      uri: "payment",
      icon: paymentSvg,
    },
    {
      name: t("collection.statistics"),
      uri: "statistics",
      icon: statisticsSvg,
      separator: true,
      disabled: true,
    },
    {
      name: t("collection.info"),
      uri: "info",
      icon: infoSvg,
    },
    {
      name: t("collection.access"),
      uri: "access",
      icon: settingsSvg,
    },
  ];

  const [menuitems, setMenuitems] = useState<Array<iMenuItem>>([]);
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const { id } = useParams();
  const collection = useSelector(selectCollection);

  const loadCollection = async (id: string) => {
    setIsLoading(true);
    try {
      const c = await refreshStoredCollection(id);
      await refreshStoredGroup(c.groupId);
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (!id) return;
    loadCollection(id);

    setMenuitems(() => {
      return menu.map((i: iMenuItem) => {
        if (pathname.includes(String(i.uri))) i.selected = true;
        return i;
      });
    });
    // eslint-disable-next-line
  }, [pathname]);

  if (!collection._id)
    return (
      <section className="d-flex justify-content-center">
        <Loader />
      </section>
    );

  return (
    <main id="collection-page">
      <div className="side">
        <p className="collection-title">{collection.name}</p>
        {menuitems.map((item, key) => {
          return (
            <div key={"menu_item_" + key}>
              <div
                className={`menu-item ${item.selected ? "selected" : ""} ${
                  item.disabled ? "disabled" : ""
                }`}
                onClick={() => {
                  if (item.uri && !item.disabled) navigate(item.uri);
                }}
              >
                <img className="menu-icon" src={item.icon} alt="" />
                <span className="menu-item-text">{item.name}</span>
                <img className="menu-icon-appear" src={crightSvg} alt="" />
              </div>
              {item.separator ? <div className="hr light"></div> : null}
            </div>
          );
        })}
      </div>
      <div className="content">
        <span className="h3">
          {t(String(menuitems.find((e) => e.selected)?.name ?? ""))}
        </span>
        <CollectionRouter />
      </div>
    </main>
  );
}
