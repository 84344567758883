import { FormEvent, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import Button from "../../../components/dom/Button";
import {
  closeModal,
  openModal,
  updateModal,
} from "../../../components/modal/Modal";
import {
  groupMember,
  groupRoles,
  GROUP_PERMISSIONS,
  iGroup,
  inviteMemberToGroup,
  refreshStoredGroup,
  removeMemberFromGroup,
  setGroupPermissionsPerUser,
} from "../../../services/groups";
import { selectGroup } from "../../../store/groupSlice";
import { selectUser } from "../../../store/userSlice";
import { formToObject } from "../../../utils/generic";
import usersSvg from "../../../assets/icons/users.svg";
import DropdownMenu from "../../../components/dom/DropdownMenu";
import settingsSvg from "../../../assets/icons/settings.svg";

export default function GroupMembers() {
  const group = useSelector(selectGroup);
  const user = useSelector(selectUser);
  const [isLoading, setIsLoading] = useState(false);

  const { t } = useTranslation();

  const canSeeMemebers = (group: iGroup) => {
    if (user.isAdmin) return true;

    const myPerms = group.members.find(
      (e) => e.email === user.email
    )?.permissions;

    if (!myPerms) return false;

    if (
      myPerms.includes(GROUP_PERMISSIONS.admin) ||
      myPerms.includes(GROUP_PERMISSIONS.memebers) ||
      myPerms.includes(GROUP_PERMISSIONS.view_members)
    )
      return true;

    return false;
  };

  const canHandleMembers = (group: iGroup) => {
    if (user.isAdmin) return true;

    const myPerms = group.members.find(
      (e) => e.email === user.email
    )?.permissions;

    if (!myPerms) return false;

    if (
      myPerms.includes(GROUP_PERMISSIONS.admin) ||
      myPerms.includes(GROUP_PERMISSIONS.memebers)
    )
      return true;

    return false;
  };

  const inviteUserToGroupSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    setIsLoading(true);
    try {
      const data = formToObject(e.target);
      await inviteMemberToGroup(data);
      await refreshStoredGroup(group._id);
      closeModal();
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };

  const inviteUserGroupModal = (
    <section id="invite-user-group-modal">
      <div className="head">
        <img src={usersSvg} alt="users icon" />
        <div className="title">
          {t("group.invite_user_text")} {group.name}
        </div>
      </div>
      <div className="body">
        <form onSubmit={inviteUserToGroupSubmit}>
          <label htmlFor="invite-user-group-form">
            {t("group.invite_user")}
          </label>
          <div className="line">
            <input
              type="email"
              required
              id="invite-user-group-form"
              name="email"
              placeholder="lorem.ipsum@email.com"
            />
            <Button
              loading={isLoading}
              type="submit"
              text={String(t("group.invite"))}
            />
          </div>
          <input
            type="text"
            hidden
            required
            name="groupId"
            defaultValue={group._id}
          />
        </form>
      </div>
    </section>
  );

  const roleFormModalSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    setIsLoading(true);
    try {
      const data = formToObject(e.target);
      await setGroupPermissionsPerUser(data);
      await refreshStoredGroup(group._id);
      closeModal();
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };

  const setRoleUserGroupModal = (email: string, selected = -1) => {
    return (
      <section id="role-user-group-modal">
        <span className="title">
          {t("group.set_role_user")} {email}
        </span>
        <div className="roles">
          {groupRoles.map((role, key) => {
            return (
              <div
                onClick={() => {
                  updateModal(setRoleUserGroupModal(email, key));
                }}
                className={`role ${key === selected ? "selected" : ""}`}
                key={"role_modal_" + key}
              >
                <p className="m-0 name">{t("group.role_" + role.name)}</p>
                <p className="m-0 ">
                  {t("group.role_description_" + role.name)}
                </p>
                {/* <p className="m-0 text-muted">
                  <i>{JSON.stringify(role.permissions)}</i>
                </p> */}
              </div>
            );
          })}
        </div>
        <form onSubmit={roleFormModalSubmit}>
          <input
            type="text"
            name="groupId"
            required
            defaultValue={group._id}
            hidden
          />
          <input
            type="email"
            name="email"
            required
            defaultValue={email}
            hidden
          />
          {groupRoles[selected]?.permissions.map((perm, key) => {
            return (
              <input
                hidden
                key={key}
                type="text"
                name="permissions[]"
                defaultValue={perm}
              />
            );
          })}
          <div className="buttons">
            <Button
              onClick={() => closeModal()}
              className="cancel"
              light
              text={String(t("group.cancel"))}
            />
            <Button
              disabled={selected < 0}
              type="submit"
              className="confirm"
              text={String(t("group.confirm_role_button"))}
            />
          </div>
        </form>
      </section>
    );
  };

  const removeUserGroupSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    setIsLoading(true);
    try {
      const data = formToObject(e.target);
      await removeMemberFromGroup(data);
      await refreshStoredGroup(group._id);
      closeModal();
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };

  const removeUserConfirmGroupModal = (email: string) => (
    <section id="remove-user-group-modal">
      <span className="title">
        {t("group.remove_user_confirm")} {email}
      </span>
      <form onSubmit={removeUserGroupSubmit}>
        <input
          type="text"
          required
          hidden
          name="groupId"
          defaultValue={group._id}
        />
        <input type="text" required hidden name="email" defaultValue={email} />
        <div className="buttons">
          <Button
            onClick={() => closeModal()}
            className="cancel"
            light
            text={String(t("group.cancel"))}
          />
          <Button
            type="submit"
            className="confirm"
            error
            text={String(t("group.confirm_remove_button"))}
          />
        </div>
      </form>
    </section>
  );

  const getRole = (member: groupMember) => {
    if (member.permissions.includes(GROUP_PERMISSIONS.admin)) return "admin";

    let perms = [...member.permissions];

    const role = groupRoles.find(
      (e) => e.permissions.sort().join(",") === perms.sort().join(",")
    );

    return role?.name;
  };

  return (
    <section className="group-sub-page">
      {canSeeMemebers(group) ? (
        <>
          <div className="d-flex mb-3">
            <span className="title">{t("group.memebers")}</span>
            {canHandleMembers(group) ? (
              <Button
                onClick={() => openModal(inviteUserGroupModal)}
                small
                className="ml-auto"
                text={String(t("group.add_memeber"))}
              />
            ) : null}
          </div>
          <div className="members">
            {group.members.map((member, key) => {
              return (
                <div key={key} className="member">
                  {canHandleMembers(group) && member.email !== user.email ? (
                    <div className="settings">
                      <DropdownMenu
                        options={[
                          {
                            text: t("group.permissions"),
                            action: () =>
                              openModal(setRoleUserGroupModal(member.email)),
                          },
                          {
                            text: t("group.remove_user"),
                            red: true,
                            action: () =>
                              openModal(
                                removeUserConfirmGroupModal(member.email)
                              ),
                          },
                        ]}
                        small
                        hovered
                        icon={settingsSvg}
                      />
                    </div>
                  ) : null}
                  <div>
                    <span>{member.email}</span>
                    {!member.isInviteAccepted ? (
                      <>
                        <span> - </span>
                        <span className="invite-pending">
                          {t("group.invite_pending")}
                        </span>
                      </>
                    ) : null}
                  </div>
                  <span className="perms text-muted">
                    {/* <i>{JSON.stringify(member.permissions)}</i> */}
                    <i> {t("group.role_" + getRole(member))}</i>
                  </span>
                </div>
              );
            })}
          </div>
        </>
      ) : (
        <span>{t("group.cant_see_members")}</span>
      )}
    </section>
  );
}
