import { FormEvent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Button from "../../components/dom/Button";
import Loader from "../../components/dom/Loader";
import { createUser, getUsersPaginated, iUser } from "../../services/auth";
import { selectUser } from "../../store/userSlice";
import cleftSvg from "../../assets/icons/cleft.svg";
import crightSvg from "../../assets/icons/cright.svg";
import { closeModal, openModal } from "../../components/modal/Modal";
import Checkbox from "../../components/dom/Checkbox";
import { formToObject } from "../../utils/generic";
import Select from "../../components/dom/Select";
import { LANG } from "../../services/i18next";

const PAGE_DIM = 25;

function CreateUserForm() {
  const [isLoading, setIsLoading] = useState(false);

  const submit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      const data = formToObject(e.target);
      await createUser(data);
      closeModal();
      window.location.reload();
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };

  if (isLoading) return <Loader />;

  return (
    <form
      onSubmit={submit}
      autoComplete="new-password"
      style={{ padding: "15px" }}
    >
      <label htmlFor="email">Email*</label>
      <input required type="email" name="email" id="email" />
      <label htmlFor="password">Password*</label>
      <input required minLength={6} type="text" name="password" id="password" />

      <label htmlFor="">First Name</label>
      <input type="text" name="firstName" />
      <label htmlFor="">Second Name</label>
      <input type="text" name="secondName" />

      <label htmlFor="">Email Verified</label>
      <Checkbox name="emailVerified" />
      <label htmlFor="">Marketing Emails</label>
      <Checkbox name="marketingEmails" />
      <label htmlFor="">ADMIN</label>
      <Checkbox name="isAdmin" />

      <label htmlFor="">Lang</label>
      <Select
        options={Object.values(LANG).map((lang) => {
          return {
            text: lang,
            value: lang,
          };
        })}
        name="lang"
      />

      <Button type="submit" text="Create" />
    </form>
  );
}

export default function Users() {
  const [users, setUsers] = useState<iUser[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(1);

  const user = useSelector(selectUser);
  const navigate = useNavigate();
  const { t } = useTranslation();

  const loadUsers = async (page = 1) => {
    setIsLoading(true);

    try {
      const data = await getUsersPaginated(page);
      setUsers(data);
    } catch (error) {
      console.log(error);
    }

    setIsLoading(false);
  };

  useEffect(() => {
    if (!user.isAdmin) navigate("");

    loadUsers(page);
  }, [user, page]);

  if (isLoading) return <Loader />;

  return (
    <main>
      <div className="container">
        <div className="content">
          <br />
          <Button
            onClick={() => openModal(<CreateUserForm />)}
            text={"Create User"}
          />
          <br />
          <table>
            <thead>
              <tr>
                <th>Email</th>
                <th>EmailVerified</th>
                <th>FirstName</th>
                <th>SecondName</th>
                <th>Wallets</th>
                <th>StripeId</th>
                <th>StripeFlag</th>
                <th>Lang</th>
                <th>MarketingEmails</th>
                {/* <th></th> */}
              </tr>
            </thead>
            <tbody>
              {users.map((user, key) => {
                return (
                  <tr key={"user_" + key}>
                    <td>{user.email}</td>
                    <td>{user.emailVerified ? "YES" : ""}</td>
                    <td>{user.firstName}</td>
                    <td>{user.secondName}</td>
                    <td>{user.wallet?.toString()}</td>
                    <td>{user.stripeId}</td>
                    <td>{user.stripeAccountDetailSubmitted ? "YES" : ""}</td>
                    <td>{user.lang}</td>
                    <td>{user.marketingEmails ? "YES" : ""}</td>
                    {/* <td>
                      <Button
                        small
                        error
                        text={String(t("dev.delete"))}
                        onClick={() => {}}
                      />
                    </td> */}
                  </tr>
                );
              })}
            </tbody>
          </table>
          <br />
          <hr />
          <br />
          {users.length >= PAGE_DIM || page > 1 ? (
            <div
              style={{ marginLeft: "50%", whiteSpace: "nowrap" }}
              className="page"
            >
              <img
                style={{ height: "20px", cursor: "pointer", margin: "0 10px" }}
                onClick={() => {
                  if (page <= 1) return;
                  setPage(page - 1);
                }}
                src={cleftSvg}
                alt="chevron icon"
                className={`chevron ${page > 1 ? "" : "disabled"}`}
              />
              <span>
                {t("nft.page")} {page}
              </span>
              <img
                style={{ height: "20px", cursor: "pointer", margin: "0 10px" }}
                onClick={() => {
                  if (users.length < PAGE_DIM) return;
                  setPage(page + 1);
                }}
                src={crightSvg}
                alt="chevron icon"
                className={`chevron ${
                  users.length >= PAGE_DIM ? "" : "disabled"
                }`}
              />
            </div>
          ) : null}
        </div>
      </div>
    </main>
  );
}
