import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import CollectionCard from "../../components/collectioncard/CollectionCard";
import { getCollections, iCollection } from "../../services/collection";
import cleftSvg from "../../assets/icons/cleft.svg";
import crightSvg from "../../assets/icons/cright.svg";
import Loader from "../../components/dom/Loader";
import { useSelector } from "react-redux";
import { selectUser } from "../../store/userSlice";
import { useTranslation } from "react-i18next";
import { getGroupsImIn, iGroup } from "../../services/groups";

const PAGE_DIM = 25;

export default function HomeCollections() {
  const [collections, setCollections] = useState<Array<iCollection>>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [invitedNotification, setInvitedNotification] = useState(false);
  const navigate = useNavigate();
  const user = useSelector(selectUser);
  const { t } = useTranslation();

  const loadCollections = async (page: number) => {
    setIsLoading(true);
    try {
      const data = await getCollections(page);
      setCollections(data);
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };

  const isInvited = (group: iGroup) => {
    return (
      group.members.find((e) => e.email === user.email)?.isInviteAccepted ===
      false
    );
  };

  const loadGroups = async () => {
    setIsLoading(true);
    try {
      const data = await getGroupsImIn();

      const defaultGroup = data.filter((g) => g.verified)[0];
      console.log(data);
      if (
        data.filter((g) => g.verified).length === 1 &&
        !isInvited(defaultGroup)
      ) {
        return navigate("/group/" + defaultGroup._id + "/collection");
      }

      if (isInvited(defaultGroup)) setInvitedNotification(true);
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (!user._id) return;

    loadGroups();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!user._id) return;

    loadCollections(page);
    // eslint-disable-next-line
  }, [page, user]);

  return (
    <main id="home-collections">
      <div className="container">
        <div className="page-title">{t("takyon.title")}</div>
        {isLoading ? (
          <div className="d-flex justify-content-center">
            <Loader />
          </div>
        ) : null}
        {collections.length === 0 && !isLoading ? (
          <div className="no-collections">
            <p className="message-title">{t("group.no_groups_title")}</p>
            <p className="message">{t("group.no_groups_message")}</p>
            <div className="box-container">
              <div onClick={() => navigate("/account/group")} className="box">
                <span className="box-title">
                  {t("group.option_create_group")}
                </span>
                <span className="box-text">
                  {t("group.option_create_group_text")}
                </span>
              </div>
              <div>{t("group.or")}</div>
              <div onClick={() => navigate("/account/group")} className="box">
                <span className="box-title">
                  {t("group.option_join_group")}
                </span>
                <span className="box-text">
                  {t("group.option_join_group_text")}
                </span>
              </div>
              {invitedNotification ? (
                <span>{t("group.you_have_invite_pending")}</span>
              ) : null}
            </div>
          </div>
        ) : null}
        <div className="collection-list">
          {collections.map((collection, key) => {
            return (
              <CollectionCard
                onUpdate={() => loadCollections(page)}
                key={key}
                collection={collection}
              />
            );
          })}
        </div>
        {collections.length >= PAGE_DIM || page > 1 ? (
          <div className="page">
            <img
              onClick={() => {
                if (page <= 1) return;
                setPage(page - 1);
                setCollections([]);
              }}
              src={cleftSvg}
              alt="chevron icon"
              className={`chevron ${page > 1 ? "" : "disabled"}`}
            />
            <span>
              {t("collection.page")} {page}
            </span>
            <img
              onClick={() => {
                if (collections.length < PAGE_DIM) return;
                setPage(page + 1);
                setCollections([]);
              }}
              src={crightSvg}
              alt="chevron icon"
              className={`chevron ${
                collections.length >= PAGE_DIM ? "" : "disabled"
              }`}
            />
          </div>
        ) : null}
      </div>
    </main>
  );
}
