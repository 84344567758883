import { FormEvent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Button from "../../../components/dom/Button";
import { closeModal, openModal } from "../../../components/modal/Modal";
import {
  createApiKey,
  deleteApiKey,
  getMyApiKeys,
  iApiKey,
} from "../../../services/apikey";
import { formToObject } from "../../../utils/generic";
import trashSvg from "../../../assets/icons/trash.svg";
import {
  editCollectionAlias,
  getCollectionAliases,
  deleteCollectionAlias,
  iCollectionAlias,
} from "../../../services/alias";
import { getCollections, iCollection } from "../../../services/collection";
import penSvg from "../../../assets/icons/pen.svg";
import cleftSvg from "../../../assets/icons/cleft.svg";
import crightSvg from "../../../assets/icons/cright.svg";

const PAGE_DIM = 25;

export default function DeveloperSettings() {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [apiKeys, setApiKeys] = useState<Array<iApiKey>>([]);
  const [aliases, setAliases] = useState<Array<iCollectionAlias>>([]);
  const [collections, setCollections] = useState<Array<iCollection>>([]);

  const [collectionPage, setCollectionPage] = useState(1);

  const loadApiKeys = async () => {
    setIsLoading(true);
    try {
      const data = await getMyApiKeys();
      setApiKeys(data);
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };

  const loadAliases = async () => {
    setIsLoading(true);
    try {
      const data = await getCollectionAliases();
      setAliases(data);
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };

  const loadCollections = async (page = 1) => {
    setIsLoading(true);
    try {
      const data = await getCollections(page);
      setCollections(data);
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    loadApiKeys();
    loadAliases();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    loadCollections(collectionPage);
  }, [collectionPage]);

  const createApiKeySubmit = async (e: FormEvent<HTMLElement>) => {
    e.preventDefault();

    setIsLoading(true);
    try {
      const data = formToObject(e.target);
      await createApiKey(data);
      closeModal();
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);

    await loadApiKeys();
  };

  const createApiKeyModal = (
    <section id="apikey-create-modal">
      <form onSubmit={createApiKeySubmit}>
        <div className="input">
          <label htmlFor="apikey-form-name">{t("dev.apikey_name")}</label>
          <input type="text" required id="apikey-form-name" name="name" />
        </div>
        <div className="button">
          <Button type="submit" text={String(t("dev.create_apikey"))} />
        </div>
      </form>
    </section>
  );

  const deleteApiKeySubmit = async (e: FormEvent<HTMLElement>) => {
    e.preventDefault();

    setIsLoading(true);
    try {
      const data = formToObject(e.target);
      await deleteApiKey(data);
      closeModal();
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);

    await loadApiKeys();
  };

  const editCollectionAliasSubmit = async (
    e: FormEvent<HTMLFormElement>,
    aliasId?: string
  ) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const data = formToObject(e.target);
      closeModal();
      if (data.alias) await editCollectionAlias(data);
      else await deleteCollectionAlias({ aliasId });
      await loadAliases();
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };

  const editCollectionAliasModal = (
    collection: iCollection,
    alias?: iCollectionAlias
  ) => (
    <section id="collection-alias-modal">
      <span className="title">
        {t("dev.collection_alias_modal_text")} {collection.name}
      </span>
      <form onSubmit={(e) => editCollectionAliasSubmit(e, alias?._id)}>
        <input type="text" name="alias" defaultValue={alias?.alias} />
        <input
          type="text"
          required
          name="collectionId"
          hidden
          defaultValue={collection._id}
        />
        <div className="buttons">
          <Button
            onClick={() => closeModal()}
            className="cancel"
            light
            text={String(t("dev.cancel"))}
          />
          <Button
            type="submit"
            className="confirm"
            text={String(t("dev.save"))}
          />
        </div>
      </form>
    </section>
  );

  const deleteApiKeyConfirmModal = (apikey: iApiKey) => (
    <section id="delete-apikey-modal">
      <span className="title">
        {t("group.delete_group_confirm")} {apikey.name}
      </span>
      <form onSubmit={deleteApiKeySubmit}>
        <input
          type="text"
          required
          hidden
          name="apikeyId"
          defaultValue={apikey._id}
        />
        <div className="buttons">
          <Button
            onClick={() => closeModal()}
            className="cancel"
            light
            text={String(t("dev.cancel"))}
          />
          <Button
            type="submit"
            className="confirm"
            error
            text={String(t("dev.confirm_delete_button"))}
          />
        </div>
      </form>
    </section>
  );

  return (
    <section>
      <Button
        className="ml-auto"
        loading={isLoading}
        onClick={() => openModal(createApiKeyModal)}
        text={String(t("dev.create_apikey"))}
      />
      <span className="title">{t("dev.api_key_list")}</span>
      <div className="apikey-list">
        {apiKeys.map((apikey, key) => {
          return (
            <div className="apikey" key={key}>
              <img
                onClick={() => openModal(deleteApiKeyConfirmModal(apikey))}
                src={trashSvg}
                alt="trash icon"
                className="apikey-delete"
              />
              <span className="title">{apikey.name}</span>
              <span>{apikey.token}</span>
            </div>
          );
        })}
      </div>
      <div className="hr"></div>
      <span className="title">{t("dev.collections")}</span>
      <p>{t("dev.collections_text")}</p>
      <table className="collection-dev-table mt-3">
        <thead>
          <tr>
            <th>{t("dev.collection_name")}</th>
            <th>{t("dev.collection_id")}</th>
            <th>{t("dev.collection_alias")}</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {collections.map((collection, key) => {
            return (
              <tr key={"collection_" + key}>
                <td>{collection.name}</td>
                <td>{collection._id}</td>
                <td>
                  {
                    aliases.find((a) => a.collectionId === collection._id)
                      ?.alias
                  }
                </td>
                <td>
                  <img
                    onClick={() => {
                      openModal(
                        editCollectionAliasModal(
                          collection,
                          aliases.find((a) => a.collectionId === collection._id)
                        )
                      );
                    }}
                    className="icon"
                    src={penSvg}
                    alt="pen edit icon"
                  />
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      <div className="d-flex justify-content-end">
        {collections.length >= PAGE_DIM || collectionPage > 1 ? (
          <div className="page">
            <img
              onClick={() => {
                if (collectionPage <= 1) return;
                setCollectionPage(collectionPage - 1);
              }}
              src={cleftSvg}
              alt="chevron icon"
              className={`chevron ${collectionPage > 1 ? "" : "disabled"}`}
            />
            <span>
              {t("nft.page")} {collectionPage}
            </span>
            <img
              onClick={() => {
                if (collections.length < PAGE_DIM) return;
                setCollectionPage(collectionPage + 1);
              }}
              src={crightSvg}
              alt="chevron icon"
              className={`chevron ${
                collections.length >= PAGE_DIM ? "" : "disabled"
              }`}
            />
          </div>
        ) : null}
      </div>
    </section>
  );
}
