import axios from "axios";
import { setCollection } from "../store/collectionSlice";
import store from "./redux";

export enum COLLECTION_PERMISSIONS {
  admin = "admin",
  update = "update",
  memebers = "memebers",
  census = "census",
  preset = "preset",
  create_nft = "create_nft",
  set_payment_method = "set_payment_method",
  view_payment_data = "view_payment_data",
}

export interface collectionMember {
  email: string;
  permissions: Array<COLLECTION_PERMISSIONS>;
  isInviteAccepted: boolean;
}

type hotelCensus = {
  type: "hotel";
  name?: string;
  stars?: string;
  location?: { label: string; value: object; address_components: object };
  email?: string;
  website?: string;
  firstName?: string;
  secondName?: string;
  note?: string;
  images?: Array<string>;

  hotelsComSlug?: string;
  hotelsComId?: string;
};

export interface iCollection {
  _id: string;
  name: string;
  groupId: string;
  gold: boolean;
  paymentMethodId: string;
  members: Array<collectionMember>;
  census: hotelCensus;
  images: Array<string>;

  nftCount?: number;
  subscriptionStatus?: string;
}

export const collectionRoles: Array<{
  name: string;
  permissions: Array<COLLECTION_PERMISSIONS>;
}> = [
  {
    name: "moderator",
    permissions: [
      COLLECTION_PERMISSIONS.update,
      COLLECTION_PERMISSIONS.memebers,
      COLLECTION_PERMISSIONS.create_nft,
      COLLECTION_PERMISSIONS.set_payment_method,
      COLLECTION_PERMISSIONS.view_payment_data,
      COLLECTION_PERMISSIONS.census,
    ],
  },
  {
    name: "rm",
    permissions: [
      COLLECTION_PERMISSIONS.update,
      COLLECTION_PERMISSIONS.memebers,
      COLLECTION_PERMISSIONS.set_payment_method,
      COLLECTION_PERMISSIONS.view_payment_data,
      COLLECTION_PERMISSIONS.census,
    ],
  },
  {
    name: "editor",
    permissions: [
      COLLECTION_PERMISSIONS.update,
      COLLECTION_PERMISSIONS.memebers,
      COLLECTION_PERMISSIONS.create_nft,
    ],
  },
  {
    name: "api",
    permissions: [COLLECTION_PERMISSIONS.create_nft],
  },
  {
    name: "member",
    permissions: [],
  },
];

export async function getCollections(
  page: number,
  groupId?: string,
  gold?: boolean
): Promise<Array<iCollection>> {
  let url = `/collection/paginate?page=${page}`;

  if (groupId) {
    url += "&groupId=" + groupId;
  }

  if (typeof gold === "boolean") {
    if (gold) url += "&gold=true";
    else url += "&gold=false";
  }

  const response = await axios.get(url);
  return response.data;
}

export async function refreshStoredCollection(
  id: string
): Promise<iCollection> {
  const collection = await getCollection(id);
  store.dispatch(setCollection(collection));
  return collection;
}

export async function createCollection(payload: any): Promise<iCollection> {
  const response = await axios.post("/collection", payload);
  return response.data;
}

export async function patchCollection(payload: any): Promise<iCollection> {
  const response = await axios.patch("/collection", payload);
  return response.data;
}

export async function getCollection(id: string): Promise<iCollection> {
  const response = await axios.get("/collection/" + id);
  return response.data;
}

export async function updateCollection(payload: any): Promise<iCollection> {
  const response = await axios.patch("/collection/", payload);
  return response.data;
}

export async function inviteMemberToCollection(
  payload: any
): Promise<iCollection> {
  const response = await axios.post("/collection/member", payload);
  return response.data;
}

export async function acceptInviteCollection(payload: any) {
  const response = await axios.post("/collection/join", payload);
  return response.data;
}

export async function declineInviteCollection(payload: any) {
  const response = await axios.post("/collection/decline", payload);
  return response.data;
}

export async function removeMemberFromCollection(payload: any): Promise<any> {
  const response = await axios.delete("/collection/member", { data: payload });
  return response.data;
}

export async function setCollectionPermissionsPerUser(
  payload: any
): Promise<any> {
  const response = await axios.patch("/collection/member", payload);
  return response.data;
}

export async function leaveCollection(payload: any) {
  const response = await axios.post("/collection/leave", payload);
  return response.data;
}

export async function deleteCollection(payload: any) {
  const response = await axios.delete("/collection", { data: payload });
  return response.data;
}

export async function transferCollection(payload: any) {
  const response = await axios.patch("/collection/transfer-ownership", payload);
  return response.data;
}
