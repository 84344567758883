import { FormEvent, useEffect, useState, useTransition } from "react";
import { useTranslation } from "react-i18next";
import {
  BillingDetails,
  createPaymentMethod,
  fulfillPayments,
  getAllPaymentMethods,
  getPendingPayments,
  getPendingPaymentStats,
  getSubscriptionLink,
  getSubscriptionPortal,
  iPaymentMethod,
  iPendingPayment,
} from "../../../services/payment";

import Button from "../../../components/dom/Button";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectUser } from "../../../store/userSlice";
import { closeModal, openModal } from "../../../components/modal/Modal";
import { datetimeToString, formToObject } from "../../../utils/generic";
import cleftSvg from "../../../assets/icons/cleft.svg";
import crightSvg from "../../../assets/icons/cright.svg";

const PAGE_DIM = 25;

interface Props {
  payment: iPaymentMethod;
  collectionId?: string;
  onExit?: Function;
  noactions?: boolean;
}

export function PaymentDetails({
  payment,
  onExit,
  noactions,
  collectionId,
}: Props) {
  const { t } = useTranslation();

  const [stats, setStats] = useState<{
    totalAmount: number;
    amountToFulfill: number;
    subscriptionAmount: {
      subscriptionStripeAmount: number;
      subscriptionTakyonAmount: number;
    };
  }>();

  const user = useSelector(selectUser);

  const [isLoading, setIsLoading] = useState(false);

  const [page, setPage] = useState(1);
  const [payments, setPayments] = useState<iPendingPayment[]>([]);
  const [subPortalLink, setSubPortalLink] = useState("");

  const [sessionLink, setSessionLink] = useState("");

  const getSessionLink = async () => {
    setIsLoading(true);
    try {
      const link = await getSubscriptionLink(payment._id);
      setSessionLink(link);
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };

  const loadSubPortalLink = async () => {
    setIsLoading(true);
    try {
      const link = await getSubscriptionPortal(collectionId ?? payment._id);
      setSubPortalLink(link);
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };

  const loadStats = async () => {
    setIsLoading(true);
    try {
      const data = await getPendingPaymentStats(payment._id, collectionId);
      setStats(data);
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };

  const loadPendingPayments = async (page = 1) => {
    setIsLoading(true);
    try {
      const data = await getPendingPayments(payment._id, page, collectionId);
      setPayments(data);
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    loadStats();
    if (payment.customerId) loadSubPortalLink();
    else getSessionLink();
  }, []);

  useEffect(() => {
    loadPendingPayments(page);
  }, [page]);

  const canFulfill = () => {
    return user.isAdmin;
  };

  const fulfillPaymentsClick = async () => {
    setIsLoading(true);
    try {
      await fulfillPayments({ paymentmethodId: payment._id });
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);

    await loadPendingPayments();
    await loadStats();
  };

  const isAdmin = () => {
    return user.isAdmin;
  };

  return (
    <>
      {!noactions ? (
        <>
          <div>
            <Button
              onClick={() => onExit?.()}
              small
              text={String(t("payment.back"))}
            />
          </div>
          <br />
          <span className="title">{payment.name}</span>
        </>
      ) : null}
      <form>
        <BillingForm billing={payment.billingDetails} disabled />
      </form>
      <div className="hr"></div>
      <>
        <span className="title">{t("payment.partner_to_takyon")}</span>
        <br />
        <p>
          <u>{t("payment.subscription_status")}</u>:{" "}
          {payment.subscriptionStatus ?? t("payment.needs_setup")}
        </p>
        <p>
          {t("payment.subscription_next_invoice")}:{" "}
          {datetimeToString(payment.subscriptionCurrentEndDate)}
        </p>
        {isAdmin() ? (
          <p>
            {t("payment.subscription_amount_takyon")}:{" "}
            {Number(stats?.subscriptionAmount.subscriptionTakyonAmount).toFixed(
              2
            )}{" "}
            €{" "}
          </p>
        ) : null}
        {!collectionId ? (
          <p>
            {t("payment.subscription_amount_stripe")}:{" "}
            {Number(stats?.subscriptionAmount.subscriptionStripeAmount).toFixed(
              2
            )}{" "}
            €{" "}
          </p>
        ) : null}
        <div>
          <a
            id="sub-portal-link"
            href={subPortalLink}
            target={"_blank"}
            hidden
            rel="noreferrer"
          >
            {subPortalLink}
          </a>
          <a id="subscription-link" href={sessionLink}></a>
          {payment.customerId ? (
            <Button
              onClick={() =>
                document.getElementById("sub-portal-link")?.click()
              }
              loading={isLoading}
              text={String(t("payment.subscription_portal"))}
            />
          ) : (
            <Button
              onClick={() =>
                document.getElementById("subscription-link")?.click()
              }
              loading={isLoading}
              text={String(t("payment.setup_subscription"))}
            />
          )}
        </div>
      </>

      <div className="hr"></div>

      <>
        <span className="title">{t("payment.takyon_to_partner")}</span>
        <br />
        <p className="m-0">
          {t("payment.total_amount")}: {stats?.totalAmount} €
        </p>
        <p>
          {t("payment.amount_to_fulfill")}: {stats?.amountToFulfill} €
        </p>

        {canFulfill() ? (
          <div>
            <Button
              loading={isLoading}
              onClick={() => fulfillPaymentsClick()}
              text={String(t("payment.fulfill_all"))}
            />
          </div>
        ) : null}

        <table className="collection-dev-table mt-3">
          <thead>
            <tr>
              <th>{t("payment.nft")}</th>
              <th>{t("payment.collection")}</th>
              <th>{t("payment.amount")} (€)</th>
              <th>{t("payment.fulfilled")}</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {payments.map((payment, key) => {
              return (
                <tr key={"collection_" + key}>
                  <td>{payment.nftId}</td>
                  <td>{payment.collectionId}</td>
                  <td>{payment.amount}</td>
                  <td>{payment.fulfilled ? "OK" : "-"}</td>
                  <td></td>
                </tr>
              );
            })}
          </tbody>
        </table>
        <div className="d-flex justify-content-end">
          {payments.length >= PAGE_DIM || page > 1 ? (
            <div className="page">
              <img
                onClick={() => {
                  if (page <= 1) return;
                  setPage(page - 1);
                }}
                src={cleftSvg}
                alt="chevron icon"
                className={`chevron ${page > 1 ? "" : "disabled"}`}
              />
              <span>
                {t("nft.page")} {page}
              </span>
              <img
                onClick={() => {
                  if (payments.length < PAGE_DIM) return;
                  setPage(page + 1);
                }}
                src={crightSvg}
                alt="chevron icon"
                className={`chevron ${
                  payments.length >= PAGE_DIM ? "" : "disabled"
                }`}
              />
            </div>
          ) : null}
        </div>
      </>
    </>
  );
}

interface BillingProps {
  billing?: BillingDetails;
  disabled?: boolean;
}

function BillingForm({ billing, disabled }: BillingProps) {
  const { t } = useTranslation();

  return (
    <>
      <label htmlFor="">{t("payment.vat")}*</label>
      <input
        disabled={disabled}
        defaultValue={billing?.vat}
        required
        type="text"
        name="billingDetails[vat]"
      />

      <label htmlFor="">{t("payment.fiscal_code")}</label>
      <input
        defaultValue={billing?.fiscalCode}
        disabled={disabled}
        type="text"
        name="billingDetails[fiscalCode]"
      />

      <label htmlFor="">{t("payment.business_name")}*</label>
      <input
        required
        defaultValue={billing?.name}
        disabled={disabled}
        type="text"
        name="billingDetails[name]"
      />

      <div className="d-flex gap-2">
        <div className="d-flex flex-column w100">
          <label htmlFor="">{t("payment.zip")}*</label>
          <input
            defaultValue={billing?.zip}
            required
            disabled={disabled}
            type="text"
            name="billingDetails[zip]"
          />
        </div>

        <div className="d-flex flex-column w100">
          <label htmlFor="">{t("payment.city")}</label>
          <input
            defaultValue={billing?.city}
            disabled={disabled}
            type="text"
            name="billingDetails[city]"
          />
        </div>
      </div>

      <label htmlFor="">{t("payment.address")}*</label>
      <input
        required
        defaultValue={billing?.address}
        disabled={disabled}
        type="text"
        name="billingDetails[address]"
      />

      <div className="d-flex gap-2">
        <div className="d-flex flex-column w100">
          <label htmlFor="">{t("payment.contact_name")}</label>
          <input
            defaultValue={billing?.contactName}
            disabled={disabled}
            type="text"
            name="billingDetails[contactName]"
          />
        </div>
        <div className="d-flex flex-column w100">
          <label htmlFor="">{t("payment.contact_phone")}</label>
          <input
            defaultValue={billing?.contactPhone}
            disabled={disabled}
            type="text"
            name="billingDetails[contactPhone]"
          />
        </div>
      </div>

      <div className="d-flex gap-2">
        <div className="d-flex flex-column w100">
          <label htmlFor="">{t("payment.recipient_code")}*</label>
          <input
            required
            defaultValue={billing?.recipientCode}
            disabled={disabled}
            type="text"
            name="billingDetails[recipientCode]"
          />
        </div>
        <div className="d-flex flex-column w100">
          <label htmlFor="">{t("payment.pec")}*</label>
          <input
            required
            defaultValue={billing?.pec}
            disabled={disabled}
            type="email"
            name="billingDetails[pec]"
          />
        </div>
      </div>
    </>
  );
}

export default function PaymentSettings() {
  const { t } = useTranslation();
  const [paymentMethods, setPaymentMethods] = useState<Array<iPaymentMethod>>(
    []
  );
  const [isLoading, setIsLoading] = useState(false);
  const user = useSelector(selectUser);

  const [isDetails, setIsDetails] = useState<iPaymentMethod | undefined>();

  const loadPaymentMethods = async () => {
    setIsLoading(true);
    try {
      const data = await getAllPaymentMethods();
      setPaymentMethods(data);
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };

  const createPaymentMethodSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    setIsLoading(true);
    try {
      const data = formToObject(e.target);
      const paymentMethod = await createPaymentMethod(data);
      const link = await getSubscriptionLink(paymentMethod._id);
      window.location.href = link;
      closeModal();
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);

    await loadPaymentMethods();
  };

  useEffect(() => {
    loadPaymentMethods();
    // eslint-disable-next-line
  }, []);

  const paymentMethodCreateModal = (
    <section id="payment-method-modal">
      <span className="title">{t("payment.create_new_text_1")}</span>
      <form onSubmit={(e) => createPaymentMethodSubmit(e)}>
        <label htmlFor="payment-form-name">{t("payment.name")}*</label>
        <input id="payment-form-name" type="text" name="name" required />

        <div className="hr"></div>
        <br />

        <span className="title">{t("payment.create_new_text_2")}</span>

        <BillingForm />

        <div className="buttons">
          <Button
            onClick={() => closeModal()}
            className="cancel"
            light
            text={String(t("payment.cancel"))}
          />
          <Button
            type="submit"
            className="confirm"
            text={String(t("payment.save"))}
          />
        </div>
      </form>
    </section>
  );

  const isInvited = (payment: iPaymentMethod) => {
    return (
      payment.members.find((e) => e.email === user.email)?.isInviteAccepted ===
      false
    );
  };

  if (isDetails)
    return (
      <PaymentDetails
        onExit={() => setIsDetails(undefined)}
        payment={isDetails}
      />
    );

  return (
    <section>
      <Button
        className="ml-auto"
        loading={isLoading}
        onClick={() => openModal(paymentMethodCreateModal)}
        text={String(t("payment.create"))}
        small
      />

      <span className="title">{t("payment.payment_list")}</span>
      <div className="list">
        <div className="group-list">
          {paymentMethods.length === 0 ? (
            <div className="group">{t("payment.no_payment_method")}</div>
          ) : null}
          {paymentMethods.map((payment, key) => {
            return (
              <div
                onClick={() => setIsDetails(payment)}
                className={`group clickable`}
                key={"settings_group_" + key}
              >
                <div className="left">
                  <div className="name">
                    <span className="h6">{payment.name}</span>
                  </div>
                </div>
                <div className="right">
                  {isInvited(payment) ? (
                    <div>
                      <Button
                        // onClick={() => acceptInviteBtn(group._id)}
                        className="my-1"
                        text={String(t("payment.accept_invite"))}
                        small
                      />
                      <Button
                        // onClick={() => declineInviteBtn(group._id)}
                        className="my-1"
                        error
                        text={String(t("payment.decline_invite"))}
                        small
                      />
                    </div>
                  ) : null}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
}
