import { useSelector } from "react-redux";
import store from "../../services/redux";
import { selectModal, setModal } from "../../store/appSlice";
import closeSvg from "../../assets/icons/close.svg";
import { toggleScroll } from "../../utils/generic";

export function openModal(element: JSX.Element) {
  toggleScroll(false);
  store.dispatch(setModal(element));
}

export function closeModal() {
  toggleScroll(true);
  store.dispatch(setModal(null));
}

export function updateModal(element: JSX.Element) {
  closeModal();
  openModal(element);
}

export default function Modal() {
  const modal = useSelector(selectModal);

  if (!modal) return <></>;

  return (
    <div onClick={() => closeModal()} id="modal-container">
      <div onClick={(e) => e.stopPropagation()} className="modal-content">
        <img
          onClick={() => closeModal()}
          src={closeSvg}
          alt="close icon"
          className="close-modal"
        />
        {modal}
      </div>
    </div>
  );
}
