import { FormEvent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import Button from "../../../components/dom/Button";
import Select from "../../../components/dom/Select";
import MapsAutocomplete from "../../../components/mapsautocomplete/MapsAutocomplete";
import {
  COLLECTION_PERMISSIONS,
  patchCollection,
  refreshStoredCollection,
} from "../../../services/collection";
import { GROUP_PERMISSIONS } from "../../../services/groups";
import { selectCollection } from "../../../store/collectionSlice";
import { selectGroup } from "../../../store/groupSlice";
import { selectUser } from "../../../store/userSlice";
import { formToObject } from "../../../utils/generic";

export default function CollectionCensus() {
  const { t } = useTranslation();
  const [type, setType] = useState<"hotel">("hotel");
  const [isLoading, setIsLoading] = useState(false);
  const [isEditButtonEnabled, setIsEditButtonEnabled] = useState(false);

  const [location, setLocation] = useState<any>();

  const collection = useSelector(selectCollection);
  const group = useSelector(selectGroup);
  const user = useSelector(selectUser);

  useEffect(() => {
    if (collection.census?.type) setType(collection.census?.type);
    if (collection.census?.location) setLocation(collection.census?.location);
  }, []);

  const canEdit = () => {
    if (user.isAdmin) return true;

    const myGroupPerms = group.members?.find(
      (e) => e.email === user.email
    )?.permissions;

    if (
      myGroupPerms?.includes(GROUP_PERMISSIONS.admin) ||
      myGroupPerms?.includes(GROUP_PERMISSIONS.collection)
    )
      return true;

    const myCollectionPerms = collection.members?.find(
      (e) => e.email === user.email
    )?.permissions;

    if (
      myCollectionPerms?.includes(COLLECTION_PERMISSIONS.admin) ||
      myCollectionPerms?.includes(COLLECTION_PERMISSIONS.census)
    )
      return true;

    return false;
  };

  const hotelType = (
    <>
      <input hidden type="text" name="type" defaultValue="hotel" />

      <span>{t("collection.hotel_basics")}</span>
      <div className="d-flex gap-3">
        <div className="d-flex flex-column">
          <label htmlFor="census-form-name">
            {t("collection.census_name")}
          </label>
          <input
            disabled={isLoading || !canEdit()}
            onInput={() => setIsEditButtonEnabled(true)}
            defaultValue={collection.census?.name}
            type="text"
            id="census-form-name"
            name="name"
          />
        </div>
        <div className="d-flex flex-column">
          <label htmlFor="census-form-stars">
            {t("collection.census_stars")}
          </label>
          <input
            disabled={isLoading || !canEdit()}
            onInput={() => setIsEditButtonEnabled(true)}
            defaultValue={collection.census?.stars}
            type="text"
            id="census-form-stars"
            name="stars"
          />
        </div>
      </div>
      <div>
        <label htmlFor="">{t("collection.census_location")}</label>
        <MapsAutocomplete
          onSelect={(e: any) => {
            setLocation(e);
            setIsEditButtonEnabled(true);
          }}
          defaultValue={collection.census?.location}
        />
      </div>
      <div className="d-flex gap-3">
        <div className="d-flex flex-column">
          <label htmlFor="census-form-email">
            {t("collection.census_email")}
          </label>
          <input
            disabled={isLoading || !canEdit()}
            onInput={() => setIsEditButtonEnabled(true)}
            defaultValue={collection.census?.email}
            type="text"
            id="census-form-email"
            name="email"
          />
        </div>
        <div className="d-flex flex-column">
          <label htmlFor="census-form-website">
            {t("collection.census_website")}
          </label>
          <input
            disabled={isLoading || !canEdit()}
            onInput={() => setIsEditButtonEnabled(true)}
            defaultValue={collection.census?.website}
            type="text"
            id="census-form-website"
            name="website"
          />
        </div>
      </div>
      <span>{t("collection.responsable_basics")}</span>
      <div className="d-flex gap-3">
        <div className="d-flex flex-column">
          <label htmlFor="census-form-firstName">
            {t("collection.census_firstName")}
          </label>
          <input
            disabled={isLoading || !canEdit()}
            onInput={() => setIsEditButtonEnabled(true)}
            defaultValue={collection.census?.firstName}
            type="text"
            id="census-form-firstName"
            name="firstName"
          />
        </div>
        <div className="d-flex flex-column">
          <label htmlFor="census-form-secondName">
            {t("collection.census_secondName")}
          </label>
          <input
            disabled={isLoading || !canEdit()}
            onInput={() => setIsEditButtonEnabled(true)}
            defaultValue={collection.census?.secondName}
            type="text"
            id="census-form-secondName"
            name="secondName"
          />
        </div>
      </div>
      <span>{t("collection.extra")}</span>
      <div className="d-flex flex-column">
        <label htmlFor="census-form-note">{t("collection.note")}</label>
        <div>
          <textarea
            disabled={isLoading || !canEdit()}
            onInput={() => setIsEditButtonEnabled(true)}
            id="census-form-note"
            cols={50}
            name="note"
            defaultValue={collection.census?.note}
          ></textarea>
        </div>
      </div>
      {user.isAdmin ? (
        <div className="d-flex gap-3">
          <div className="d-flex flex-column">
            <label htmlFor="census-form-hotelsComSlug">
              {t("collection.census_hotelsComSlug")}
            </label>
            <input
              disabled={isLoading || !canEdit()}
              onInput={() => setIsEditButtonEnabled(true)}
              defaultValue={collection.census?.hotelsComSlug}
              type="text"
              id="census-form-hotelsComSlug"
              name="hotelsComSlug"
            />
          </div>
          <div className="d-flex flex-column">
            <label htmlFor="census-form-hotelsComId">
              {t("collection.census_hotelsComId")}
            </label>
            <input
              disabled={isLoading || !canEdit()}
              onInput={() => setIsEditButtonEnabled(true)}
              defaultValue={collection.census?.hotelsComId}
              type="text"
              id="census-form-hotelsComId"
              name="hotelsComId"
            />
          </div>
        </div>
      ) : null}
    </>
  );

  const saveCensusSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      let census = formToObject(e.target);
      if (location) census.location = location;
      await patchCollection({ census, collectionId: collection._id });
      await refreshStoredCollection(collection._id);
      setIsEditButtonEnabled(false);
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };

  return (
    <section className="collection-sub-page">
      <label htmlFor="">{t("collection.census_type")}</label>
      <div>
        <Select
          disabled={isLoading || !canEdit()}
          defaultValue={type}
          onChange={(e) => {
            setType(e.target.value as any);
          }}
          options={[
            { value: "hotel", text: String(t("collection.census_hotel")) },
          ]}
        />
      </div>
      <form onSubmit={saveCensusSubmit} className="mt-4">
        {type === "hotel" ? hotelType : null}
        {canEdit() ? (
          <div>
            <Button
              light={!isEditButtonEnabled}
              disabled={!isEditButtonEnabled}
              type="submit"
              loading={isLoading}
              text={String(t("collection.census_save"))}
            />
          </div>
        ) : null}
      </form>
    </section>
  );
}
