import { FormEvent, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import Button from "../../../components/dom/Button";
import DropdownMenu from "../../../components/dom/DropdownMenu";
import Loader from "../../../components/dom/Loader";
import { COLLECTION_PERMISSIONS } from "../../../services/collection";
import { GROUP_PERMISSIONS } from "../../../services/groups";
import {
  deleteNft,
  getNftsOfCollectionPaginated,
  iNft,
  NFT_OWNER_TYPE,
  NFT_SELL_STATUS,
} from "../../../services/nft";
import { debounce } from "lodash";
import { selectCollection } from "../../../store/collectionSlice";
import { selectGroup } from "../../../store/groupSlice";
import { selectUser } from "../../../store/userSlice";
import { datetimeToString, formToObject } from "../../../utils/generic";
import cleftSvg from "../../../assets/icons/cleft.svg";
import crightSvg from "../../../assets/icons/cright.svg";
import { useNavigate } from "react-router-dom";
import { closeModal, openModal } from "../../../components/modal/Modal";
import searchSvg from "../../../assets/icons/search.svg";
import viewSvg from "../../../assets/icons/view.svg";

const PAGE_DIM = 25;

export default function CollectionNfts() {
  const { t } = useTranslation();
  const [nfts, setNfts] = useState<Array<iNft>>([]);

  const collection = useSelector(selectCollection);
  const group = useSelector(selectGroup);
  const user = useSelector(selectUser);

  const [page, setPage] = useState(1);
  const [textQuery, setTextQuery] = useState<any>({});

  const [isLoading, setIsLoading] = useState(true);
  const [searchText, setSearchText] = useState("");

  const [mode, setMode] = useState<"all" | "past" | "active">("all");
  const [modeQuery, setModeQuery] = useState<any>({});

  const navigate = useNavigate();

  const canEditNft = () => {
    if (user.isAdmin) return true;

    return user.isAdmin ? true : false;
  };

  const canCreateNft = () => {
    if (user.isAdmin) return true;

    const myGroupPerms = group.members?.find(
      (e) => e.email === user.email
    )?.permissions;

    if (
      myGroupPerms?.includes(GROUP_PERMISSIONS.admin) ||
      myGroupPerms?.includes(GROUP_PERMISSIONS.collection)
    )
      return true;

    const myCollectionPerms = collection.members?.find(
      (e) => e.email === user.email
    )?.permissions;

    if (
      myCollectionPerms?.includes(COLLECTION_PERMISSIONS.admin) ||
      myCollectionPerms?.includes(COLLECTION_PERMISSIONS.create_nft)
    )
      return true;

    return false;
  };

  const loadNfts = async (page = 1, textQuery: any, modeQuery: any) => {
    setIsLoading(true);
    try {
      const data = await getNftsOfCollectionPaginated(collection._id, page, {
        ...textQuery,
        ...modeQuery,
      });
      setNfts(data);
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };

  const deleteNftSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    setIsLoading(true);
    try {
      const data = formToObject(e.target);
      closeModal();
      await deleteNft(data);
      await loadNfts(page, textQuery, modeQuery);
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };

  const deleteNftModal = (id: string) => {
    return (
      <section id="remove-user-group-modal">
        <span className="title">
          {t("nft.delete_confirm")} {id}
        </span>
        <form onSubmit={deleteNftSubmit}>
          <input type="text" required hidden name="nftId" defaultValue={id} />
          <div className="buttons">
            <Button
              onClick={() => closeModal()}
              className="cancel"
              light
              text={String(t("nft.cancel"))}
            />
            <Button
              type="submit"
              className="confirm"
              error
              text={String(t("nft.confirm_delete_button"))}
            />
          </div>
        </form>
      </section>
    );
  };

  const nftDetailModal = (nft: iNft) => {
    console.log(nft);
    return (
      <section id="nft-detail-modal">
        <a
          target={"_blank"}
          rel="noreferrer"
          href={`${process.env.REACT_APP_FRONTEND_URI}/booking/${nft._id}`}
        >
          {t("nft.view_exchange")}
        </a>
        <p className="title">{t("nft.info")}</p>
        <p>
          {t("nft.id")}: {String(nft._id).substring(String(nft._id).length - 6)}
        </p>
        {user.isAdmin ? (
          <p>
            {t("nft.id_full")}: {nft._id}
          </p>
        ) : null}
        {user.isAdmin ? (
          <p>
            {t("nft.solidity_id")}: {nft.solidityId}
          </p>
        ) : null}
        <p>
          {t("nft.created_at")}: {datetimeToString(nft._createdAt, true, true)}
        </p>
        <p className="title">{t("nft.census")}</p>
        <div>
          <p>
            <u>{t("nft.hotel")}</u>: {nft.payload?.hotel}
          </p>
          <p>
            <u>{t("nft.stars")}</u>: {nft.payload?.stars}
          </p>
        </div>
        <p>
          <u>{t("nft.website")}</u>: {nft.payload?.website}
        </p>
        <p>
          <u>{t("nft.location")}</u>: {nft.payload?.location?.label}
        </p>
        <p>
          <u>{t("nft.note")}</u>: {nft.payload?.note}
        </p>
        <p className="title">{t("nft.booking")}</p>
        <p>
          <u>{t("nft.reference")}</u>: {nft.payload?.reference}
        </p>
        <p>
          <u>{t("nft.original_price")}</u>: {nft.originalPrice}€
        </p>
        <p>
          <u>{t("nft.current_price")}</u>:{" "}
          {nft.currentPrice ?? nft.originalPrice}€
        </p>
        <p>
          <u>{t("nft.checkin")}</u>: {datetimeToString(nft.payload?.checkin)}
        </p>
        <p>
          <u>{t("nft.checkout")}</u>: {datetimeToString(nft.payload?.checkout)}
        </p>
        <p>
          <u>{t("nft.extra")}</u>: {nft.payload?.extra}
        </p>
        <p className="title">{t("nft.guests")}</p>
        <p>
          <u>{t("nft.owner")}</u>: {nft.owner}
        </p>
        {nft.guests?.map((guest, key) => {
          return (
            <p key={"guest_" + key}>
              - {guest.firstName} {guest.secondName} {guest.email}
            </p>
          );
        })}
        <p className="title">{t("nft.rooms")}</p>
        {nft.payload?.rooms?.map((room, key) => {
          return (
            <p key={"room_" + key}>
              - {room.name}: {room.guestsAdults ?? 0} {t("nft.guest_adults")}{" "}
              {room.guestsKids ?? 0} {t("nft.guest_kids")} / ({room.amenities})
            </p>
          );
        })}
        <p className="title">{t("nft.status")}</p>
        <p>
          {nft.sellStatus === NFT_SELL_STATUS.FOR_SALE
            ? t("nft.for_sale")
            : t("nft.not_for_sale")}
        </p>
        <p className="title">{t("nft.owner_type")}</p>
        <p>
          {nft.ownerType === NFT_OWNER_TYPE.ORIGINAL
            ? t("nft.owner_original")
            : t("nft.owner_resold")}
        </p>
        {user.isAdmin ? (
          <>
            <p className="title">{t("nft.status_blockchain")}</p>
            <p>{nft.blockchainStatus}</p>
          </>
        ) : null}
        <div className="hr"></div>
        <p className="title">{t("nft.images")}</p>
        {!nft.images || nft.images.length === 0 ? (
          <>{t("nft.no_images")}</>
        ) : null}
        <div className="images-container d-flex flex-wrap">
          {nft.images?.map((img, key) => {
            return (
              <div
                key={"img_" + key}
                style={{
                  background: `url(${img})`,
                  height: "100px",
                  width: "100px",
                  backgroundPosition: "center center",
                  backgroundSize: "cover",
                }}
              ></div>
            );
          })}
        </div>
      </section>
    );
  };

  useEffect(() => {
    loadNfts(page, textQuery, modeQuery);
    // eslint-disable-next-line
  }, [page]);

  const searchCallbackDebounced = useRef(debounce(loadNfts, 1000));

  const isQueryGood = (query: string) => {
    return /\S/.test(query) && query.length >= 1;
  };

  const searchInput = (text: string) => {
    if (!text || !isQueryGood(text)) {
      setTextQuery({});
      loadNfts(page, {}, modeQuery);
      return;
    }

    const textQ = {
      $or: [
        { _id: { $regex: text } },
        { owner: { $regex: text } },
        { "payload.reference": { $regex: text } },
        { "payload.room": { $regex: text } },
      ],
    };

    setTextQuery(textQ);
    searchCallbackDebounced.current(page, textQ, modeQuery);
  };

  return (
    <section>
      <div className="d-flex justify-content-end">
        {canCreateNft() ? (
          <Button
            onClick={() => {
              if (!group.verified)
                return alert(
                  "Your group is not verified. Verify your group first"
                );

              if (
                collection.subscriptionStatus !== "active" &&
                collection.subscriptionStatus !== "trialing"
              )
                return alert(
                  "Your collectiona has not PaymentMethod with active subscription. Activate subscription first."
                );

              navigate("edit");
            }}
            text={String(t("nft.create_new"))}
          />
        ) : null}
      </div>
      <div className="d-flex gap-2 mt-2">
        <Button
          disabled={isLoading || mode === "all"}
          light={mode !== "all"}
          small
          text={String(t("nft.all"))}
          onClick={() => {
            setMode("all");
            setModeQuery({});
            loadNfts(1, textQuery, {});
          }}
        />
        <Button
          disabled={isLoading || mode === "active"}
          light={mode !== "active"}
          small
          text={String(t("nft.active"))}
          onClick={() => {
            setMode("active");
            const modeQ = {
              "payload.checkout": {
                $gt: new Date(),
              },
            };
            setModeQuery(modeQ);
            loadNfts(1, textQuery, modeQ);
          }}
        />
        <Button
          disabled={isLoading || mode === "past"}
          light={mode !== "past"}
          small
          text={String(t("nft.past"))}
          onClick={() => {
            setMode("past");
            const modeQ = {
              "payload.checkout": {
                $lt: new Date(),
              },
            };
            setModeQuery(modeQ);
            loadNfts(1, textQuery, modeQ);
          }}
        />
      </div>
      <div className="search-nft">
        <input
          value={searchText}
          onChange={(e) => {
            setSearchText(e.target.value);
            searchInput(e.target.value);
          }}
          className="w100"
          type="text"
          placeholder={String(t("nft.search_placeholder"))}
        />
        <img src={searchSvg} alt="search icon" className="icon" />
      </div>
      {isLoading ? (
        <div className="d-flex justify-content-center">
          <Loader />
        </div>
      ) : (
        <table className="mt-3">
          <thead>
            <tr>
              <th>{t("nft.details")}</th>
              <th>#</th>
              <th>{t("nft.booking_ref")}</th>
              <th>{t("nft.hotel")}</th>
              <th>{t("nft.owner")}</th>
              <th>{t("nft.guests")}</th>
              <th>
                {t("nft.checkin")} - {t("nft.checkout")}
              </th>
              <th>
                {t("nft.current_price")} - {t("nft.original_price")}
              </th>
              {canEditNft() ? <th>{t("nft.actions")}</th> : null}
            </tr>
          </thead>
          <tbody>
            {nfts.map((nft) => (
              <tr key={"nft_" + nft._id}>
                <td>
                  <img
                    className="view-icon"
                    onClick={() => {
                      console.log(nft);
                      openModal(nftDetailModal(nft));
                    }}
                    src={viewSvg}
                    alt="eye icon"
                  />
                </td>
                <td>{String(nft._id).substring(String(nft._id).length - 6)}</td>
                <td>{nft.payload?.reference}</td>
                <td>{nft.payload?.hotel}</td>
                <td>{nft.owner}</td>
                <td>
                  {nft.payload?.rooms?.reduce((total, room) => {
                    return (
                      total + (room.guestsAdults ?? 0) + (room.guestsKids ?? 0)
                    );
                  }, 0)}
                </td>
                <td>
                  {datetimeToString(nft.payload?.checkin, true)} -{" "}
                  {datetimeToString(nft.payload?.checkout, true)}
                </td>
                <td>
                  {Number(nft.currentPrice ?? nft.originalPrice).toFixed(2)}€ -{" "}
                  {Number(nft.originalPrice).toFixed(2)}€
                </td>
                {canEditNft() ? (
                  <td>
                    <DropdownMenu
                      small
                      hovered
                      options={[
                        {
                          text: t("nft.edit"),
                          action: () => navigate("edit/" + nft._id),
                        },
                        {
                          text: t("nft.delete"),
                          action: () =>
                            openModal(deleteNftModal(String(nft._id))),
                          red: true,
                        },
                      ]}
                    />
                  </td>
                ) : null}
              </tr>
            ))}
          </tbody>
        </table>
      )}
      <div className="d-flex justify-content-end">
        {nfts.length >= PAGE_DIM || page > 1 ? (
          <div className="page">
            <img
              onClick={() => {
                if (page <= 1) return;
                setPage(page - 1);
              }}
              src={cleftSvg}
              alt="chevron icon"
              className={`chevron ${page > 1 ? "" : "disabled"}`}
            />
            <span>
              {t("nft.page")} {page}
            </span>
            <img
              onClick={() => {
                if (nfts.length < PAGE_DIM) return;
                setPage(page + 1);
              }}
              src={crightSvg}
              alt="chevron icon"
              className={`chevron ${nfts.length >= PAGE_DIM ? "" : "disabled"}`}
            />
          </div>
        ) : null}
      </div>
    </section>
  );
}
